import { Canvas, useThree } from '@react-three/fiber';
import { GlobalContext, GlobalContextProvider, useGlobalContext } from 'context/global';
import { ThreeContext, ThreeContextProvider, useThreeContext } from 'context/three';
import { ReactNode, Suspense, useContext, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import {ResizeObserver} from '@juggle/resize-observer';



interface CanvasProps{
	panel? : boolean
}

const onGoRight = keyframes`
	0%{
		margin-left:0%;
	}
	100%{
		margin-left:50%;
	}
`;

const onGoLeft = keyframes`
	0%{
		margin-left:50%;
	}
	100%{
		margin-left:0%;
	}
`;

const CanvasContainer = styled.div<CanvasProps>`
	margin-left:0%;
	${p => p.panel !== undefined && p.panel ? css`
		animation: ${p.panel? onGoRight : onGoLeft} 2s forwards};
		`
		: p.panel !== undefined && !p.panel ? css`
			animation: ${p.panel? onGoRight : onGoLeft} 2s forwards};
		` : ''
}
	width: 100%;
	transition: all 3s;
	/* z-index:2; */
`;

const Overflow = styled.div<CanvasProps>`
    position: absolute;
	overflow: hidden;
	left:0;
	top:0;
	right:0;
	bottom: 0;
	width:100%;
	height: 100vh;
	display: flex;
	justify-content: center;
`;


type ThreeCanvasProps = {
    children: ReactNode;
}

const camera = {
	position:[0,0,2],
	fov: 90,
};

const ThreeCanvas = ({children}: ThreeCanvasProps) => {

	const globalContextValues = useContext(GlobalContext);
	const threeContextValues = useContext(ThreeContext);
	const {upgradePanelActive, tutorial} = useGlobalContext();

	const [state, setState] = useState<number>(0);

	useEffect(() => {
		if(state === 0){
			setState(1);
		}
		if(state === 1){
			setState(2);
		}
	}, [upgradePanelActive]);

	const {activeScene} = useThreeContext();

	return (
		<>
			{ activeScene && !tutorial &&
			<Overflow>
				<CanvasContainer panel={state === 2 ? upgradePanelActive : undefined}>
					<Canvas camera={camera} shadows resize={{polyfill: ResizeObserver}}>
						{/* <color attach="background" args={['black']} /> */}
						{/* Insert GlobalContextProvider */}
						<GlobalContext.Provider value={globalContextValues}>
							<ThreeContext.Provider value={threeContextValues}>
								{children}
							</ThreeContext.Provider>
						</GlobalContext.Provider>
					</Canvas>
				</CanvasContainer>
			</Overflow>
			}

		</>
	);
};

export default ThreeCanvas;
