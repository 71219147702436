import { Fireworks } from 'fireworks-js/dist/react';
import { useEffect, useState } from 'react';

const options = {
	speed: 3
};
const Firework = () => {

	const [stop, setStop] = useState<boolean>(true);

    
	useEffect(() =>{
		setTimeout(() => {
			setStop(false);
		}, 9000);
	},[]);
	return (
		<>
			{stop && <Fireworks 

				options={options} style={{
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					position: 'absolute',
					pointerEvents: 'none',
				}} />
			}
		</>
	);
};

export default Firework;