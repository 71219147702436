import { screens } from 'components/Screens';
import { useGlobalContext } from 'context/global';
import { ConsoleLabel, ConsoleRow } from './components';

const ConsoleGameState = () => {

	const { setGameState, gameState } = useGlobalContext();

	return (  
		<ConsoleRow>
			<ConsoleLabel>Label</ConsoleLabel>
			<select onChange={(e) => setGameState && setGameState(parseInt(e.target.value))} value={gameState}>
				{[...Array(screens.length+1)].map((x, index) => (<option key={`console-gamestate-option-${index}`} label={String(index)} value={index}/>))}
			</select>
		</ConsoleRow>
	);
};
 
export default ConsoleGameState;