import { createContext, useContext, useEffect, useState } from 'react';
import { contextProps, globalContextProps} from './types';

const GlobalContext = createContext<Partial<globalContextProps>>({});

const GlobalContextProvider = (props: contextProps) => {


	//#region functions

	const onSetDisplayConsole = (val) => {
		setDisplayConsole(val);
	};
	
	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};

	//#endregion

	//#region useStates
	const [ tutorial, setTutorial] = useState<boolean>(false);
	const [ displayConsole, setDisplayConsole] = useState<boolean>(false);
	const [ gameState, setGameState] = useState<number>(1);

	// player Choices 

	const [ advisor, setAdvisor ] = useState<number>(0);
	const [ building, setBuilding ] = useState<number>(0);

	// upgrades

	const [ upgradePanelActive, setUPA ] = useState<boolean>(false);
	const [ activeCategory, setActiveCategory ] = useState<number | undefined>();

	const setUpgradePanelActive = (b:boolean) => {
		if(!b){
			setTimeout(()=>setActiveCategory(undefined),1000);
		}
		setUPA(b);
	};

	// progression

	const [categoriesCompleted, setCategoriesCompleted] = useState<boolean[]>([false,false,false]);
	const [showCategories, setShowCategories] = useState<boolean[]>([true,false,false]);

	useEffect(() => {
		if(categoriesCompleted[0]){
			setShowCategories([true,true,false]);	
		}
		if(categoriesCompleted[1]){
			setShowCategories([true,true,true]);	
		}
	},[categoriesCompleted]);

	// Camera controls

	const [cameraPos, setCameraPos] = useState<number>(0);
	const [cameraPosCallback, setCameraPosCallback] = useState<() => () => void | undefined>();
	
	const setCameraPosReached = () => {
		if(cameraPosCallback){
			cameraPosCallback();
			setCameraPosCallback(undefined);
			setTimeout(() => {
				setCameraPos(0);
			},2000);
		}
	};

	const setCameraPosition = (a, callback) => {
		setCameraPos(a);
		setCameraPosCallback(() => callback);
	};

	//#endregion

	//#region Passed Props

	const passedFunctions = {

		//console

		setDisplayConsole,
		onSetDisplayConsole,
		onToggleDisplayConsole,

		// 
		setTutorial,
		setGameState,

		// player Choices

		setAdvisor,
		setBuilding,

		// upgrades

		setUpgradePanelActive,
		setActiveCategory,
		setCategoriesCompleted,
		setShowCategories,

		// camera controls

		setCameraPosition,
		setCameraPosReached,
		setCameraPos,

	};

	const passedValues = {

		//console

		displayConsole,

		//
		gameState,

		// player Choices

		advisor,
		building,

		// upgrades

		upgradePanelActive,
		activeCategory,


		// progression

		categoriesCompleted,
		showCategories,
		tutorial,
		
		// camera pos

		cameraPos,

	};

	//#endregion

	//#render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
