/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { tennisbaan } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    base_ground_tennis: THREE.Mesh
    grass_tennis: THREE.Mesh
    tennisbuilding: THREE.Mesh
    tennis_flora: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(tennisbaan) as GLTFResult;
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				geometry={nodes.base_ground_tennis.geometry}
				material={nodes.base_ground_tennis.material}
				position={[0.4, 0, -0.23]}
				rotation={[-Math.PI, 0, -Math.PI]}
			/>
			<mesh
				geometry={nodes.grass_tennis.geometry}
				material={nodes.grass_tennis.material}
				position={[0.49, 0.07, 1.26]}
				rotation={[0, Math.PI / 2, 0]}
			/>
			<mesh
				geometry={nodes.tennisbuilding.geometry}
				material={nodes.tennisbuilding.material}
				position={[0.4, 0.72, -1.23]}
			/>
			<mesh
				geometry={nodes.tennis_flora.geometry}
				material={nodes.tennis_flora.material}
				position={[0.42, 0.13, 0.06]}
				rotation={[Math.PI / 2, 0, 2.97]}
				scale={0.07}
			/>
		</group>
	);
}

useGLTF.preload(tennisbaan);
