import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface TypingProps {
	children: string,
	delay?: number, // delay in ms
	animationDuration?: number,
	className?: string,
}
 
const Typing = (props: TypingProps) => {

	const [string, setString] = useState<string>();

	useEffect(() => {
		if(props.children && props.children !== string){
			setString(props.children);
		}
	});

	return (  
		<Container className={props.className}>
			{ string && string.split('').map((char, index) => 

				<Char 
					key={`splitstring-${string}-${index}`} 
					delay={props.delay ? index * props.delay : index * 100}
					animationDuration={props.animationDuration}
				>
					{char}
				</Char>
			
			)}
		</Container>
	);
};

// keyframes

const fadeIn = keyframes`
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
`;

// styled components

const Container = styled.div`

`;

const Char = styled.span<{delay: number, animationDuration: number | undefined}>`
	
	opacity:0;

	animation: ${fadeIn} ${p => p.animationDuration ? p.animationDuration : 500}ms forwards;
	animation-delay: ${p => p.delay}ms;

`;
 
export default Typing;