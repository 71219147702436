export type Category = {
	upgrades: string[];//referentie naar upgrades
	buttonPosition: number[]; // Array of 3 numbers like so: [0,0,0]
}

type Scene = {
	categories: Category[];
}

type SceneRecord = {
	[Key in string]?:Scene;
}

const scenes : SceneRecord = {
	scene01 :{
		categories: [
			{
				upgrades: ['scene01_cat1_upgrade01','scene01_cat1_upgrade02', 'scene01_cat1_upgrade03'],
				buttonPosition: [1, 1, -3],
			},
			{
				upgrades: ['scene01_cat2_upgrade01','scene01_cat2_upgrade02'],
				buttonPosition: [2, 1, 1],
			},
			{
				upgrades: ['scene01_cat3_upgrade01','scene01_cat3_upgrade02', 'scene01_cat3_upgrade03'],
				buttonPosition: [-3, 1, 1],
			},
		],
	} , 
	scene02 :{
		categories: [
			{
				upgrades: ['scene02_cat1_upgrade01','scene02_cat1_upgrade02', 'scene02_cat1_upgrade03'],
				buttonPosition: [1, 1, -3],
			},
			{
				upgrades: ['scene02_cat2_upgrade01','scene02_cat2_upgrade02', 'scene02_cat2_upgrade03'],
				buttonPosition: [2, 1, 1],
			},
			{
				upgrades: ['scene02_cat3_upgrade01','scene02_cat3_upgrade02'],
				buttonPosition: [-3, 1, 1],
			},
		],
	} ,
	scene03 :{
		categories: [
			{
				upgrades: ['scene03_cat1_upgrade01','scene03_cat1_upgrade02', 'scene03_cat1_upgrade03'],
				buttonPosition: [1, 1, -3],
			},
			{
				upgrades: ['scene03_cat2_upgrade01','scene03_cat2_upgrade02', 'scene03_cat2_upgrade03'],
				buttonPosition: [1.5, 1, 1],
			},
			{
				upgrades: ['scene03_cat3_upgrade01','scene03_cat3_upgrade02'],
				buttonPosition: [-3, 1, 1],
			},
		],
	} 
};

export default scenes;
