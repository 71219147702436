/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { dakisolatie_upgrade_m } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    gras: THREE.Mesh
    grond: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
    //kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Dakisolatie_upgrade({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(dakisolatie_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);


	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);
	
	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<mesh
				name="gras"
				castShadow
				receiveShadow
				geometry={nodes.gras.geometry}
				material={nodes.gras.material}
			/>
			<mesh
				name="grond"
				castShadow
				receiveShadow
				geometry={nodes.grond.geometry}
				material={nodes.grond.material}
				morphTargetDictionary={nodes.grond.morphTargetDictionary}
				morphTargetInfluences={nodes.grond.morphTargetInfluences}
			/>
		</group>
	);
}

useGLTF.preload(dakisolatie_upgrade_m);
