import { arrowdown_hover, arrowdown_normal, arrowup_hover, arrowup_normal } from 'assets/icons';
import { Andrea, Ariean, Celeste, Justin, Lisa } from 'assets/images';
import advisors from 'data/advisors';
import styled from 'styled-components';
import SelectionContainer from '../components/SelectionContainer';
 
type AdvisorSelectionProps = {
	setSelected: (i:number)=>void;
}


const AdvisorSelection = (props:AdvisorSelectionProps) => {
	return (  
		<>
			<SelectionContainer setSelected={(i) => {props.setSelected(i);}}>
				{ advisors.map((value, index) => {
					return(<>
						<AdvisorPortrait key={`advisor-selection-portrait-${index}`} img={value.portrait}/>	
						<Description>
							{value.name}
							<div>{value.job}</div>
						</Description>
					</>	
					);
				})}
			</SelectionContainer>
		</>
	);
};

// styled components

const AdvisorPortrait = styled.div<{img:string}>`

	width:50%;
	padding-top:50%;

	background-color: #D9D9D9;
	border-radius: 100%;

	background-image: url(${p => p.img});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: ${p => p.theme.media.lg}){
        border: solid ${p => p.theme.borderSize.sm} ${p=>p.theme.colors.secondary};
    }

    @media (min-width: ${p => p.theme.media.lg}){
        border: solid ${p => p.theme.borderSize.lg} ${p=>p.theme.colors.secondary};
    }
`;

const Description = styled.div`
		padding: 2%;
		text-align: center;
		color: white;
		font-weight: bold;
		font-size: 2vw;
		& > div {
			font-weight: normal;
		}
`;
// data 

export default AdvisorSelection;