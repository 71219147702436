import { useThreeContext } from 'context/three';
import { useEffect, useState } from 'react';
import Battery_upgrade from '../objects/Upgrades/Battery_01';
import Warmte_vries_cointainer_upgrade from '../objects/Upgrades/Container_warmte-koud-opslag_upgrade';
import Ecobarrel_upgrade from '../objects/Upgrades/Ecovat_upgrade';
import Flora_01 from '../objects/Upgrades/Flora_01';
import Flora_02 from '../objects/Upgrades/Flora_02';
import Flora_03 from '../objects/Upgrades/Flora_03';

import Led_upgrade from '../objects/Upgrades/Led_upgrade';
import Zwembadcover_upgrade from '../objects/Upgrades/Pool_cover_upgrade';
import Dakisolatie_upgrade from '../objects/Upgrades/Roofisolation_01';
import Solarpanels_upgrade from '../objects/Upgrades/Solarpanels';
import Warmtepanelen_upgrade from '../objects/Upgrades/Warmtepaneel_upgrade';
import Zwembad_roof from '../objects/Zwembad/Swimmingpool_roof';
import Zwembad from '../objects/Zwembad/Zwembad';



const Scene = () => {
	
	const {activeUpgrade} = useThreeContext();
	const [greenUpgrades, setGreenUpgrades] = useState<boolean[]>([false, false, false]);

	useEffect(() => {
		if(activeUpgrade){
			if(activeUpgrade[0] || activeUpgrade[1] || activeUpgrade[2]){
				setGreenUpgrades([true, greenUpgrades[1], greenUpgrades[2]]);
			}
			if(activeUpgrade[3] || activeUpgrade[4]){
				setGreenUpgrades([greenUpgrades[0], true, greenUpgrades[2]]);
			}
			if(activeUpgrade[5] || activeUpgrade[6] || activeUpgrade[7]){
				setGreenUpgrades([greenUpgrades[0], greenUpgrades[1], true]);
			}
		}
	},[activeUpgrade]);

	return(
		<group rotation={[0, 2, 0]}>	
			<Zwembad/>
			<group position={[0, 0.6, 0]}>
				<Zwembad_roof upgraded={activeUpgrade && activeUpgrade[2]}/>  
			</group> 
			<group position={[-2, 0.084, 1]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[1.6, 0.084, 1.8]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[1.4, 0.084, 2]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[-2, 0.084, 1.4]}>
				<Flora_02 upgraded={greenUpgrades[1]}/>
			</group>
			<group position={[1.645, 0.084, 0.8]}>
				<Flora_03 upgraded={greenUpgrades[2]}/>  
			</group> 		
			<group position={[1.645, 0.084, -1.891]}>
				<Zwembadcover_upgrade upgraded={activeUpgrade && activeUpgrade[0]}/>
			</group>
			<group scale={1.5} position={[-0.741, 1.723, -0.925]} >
				<Dakisolatie_upgrade upgraded={activeUpgrade && activeUpgrade[1]}/>
			</group>
			<group scale={0.35} position={[-0.7, 1.85, -0.5]}>			
				<Led_upgrade upgraded={activeUpgrade && activeUpgrade[2]}/>
			</group>
			<group position={[-0.11, 1.698, -0.186]}>
				<group position={[-0.6, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[-1.2, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[0, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
			</group>
			<group position={[-0.4, 1.696, -1.75]}>
				<group rotation={[0, 1.6, 0]}  position={[0.141, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group rotation={[0, 1.6, 0]}  position={[-0.399, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group rotation={[0, 1.6, 0]}  position={[-0.998, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
			</group>
			<group position={[0.889, 0.05, 1.8]}>
				<Warmte_vries_cointainer_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
			</group>
			<group scale={0.7} position={[1.6934, 0, 1.7464]}>
				<Ecobarrel_upgrade upgraded={activeUpgrade && activeUpgrade[6]}/>
			</group>
			<group scale={0.35} position={[-1.989, 0, 0.437]}>
				<Battery_upgrade upgraded={activeUpgrade && activeUpgrade[7]}/>
			</group>
		</group>  
	);
};

export default Scene;