/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { solarpanels_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';
 
type GLTFResult = GLTF & {
  nodes: {
    ['solarpanel2-White']: THREE.Mesh
    ['solarpanel2-glass_02']: THREE.Mesh
    ['solarpanel2-Metal']: THREE.Mesh
    ['solarpanel1-Metal']: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
    ['glass_02.003']: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Solarpanels_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(solarpanels_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);

	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<group>
				<mesh
					geometry={nodes['solarpanel2-White'].geometry}
					material={nodes['solarpanel2-White'].material}
				/>
				<mesh geometry={nodes['solarpanel2-glass_02'].geometry} material={materials['glass_02.003']} />
				<mesh geometry={nodes['solarpanel2-Metal'].geometry} material={nodes['solarpanel2-Metal'].material} />
			</group>
			<group>
				<mesh
					name="solarpanel1-Metal"
					geometry={nodes['solarpanel1-Metal'].geometry}
					material={nodes['solarpanel1-Metal'].material}
				/>
			</group>
		</group>
	);
}

useGLTF.preload(solarpanels_upgrade_m);
