import { indicator, meterlabels } from 'assets/images';
import { Header } from 'components/Typography';
import styled, { css } from 'styled-components';

const Meter = ({amountCompleted} : {amountCompleted: number}) => {
	return (  
		<Container>
			<StyledHeader style={{textAlign: 'center'}}>duurzaamheidsmeter</StyledHeader>
			<Label>
				<IndicatorContainer state={amountCompleted}>
					<Indicator/>
				</IndicatorContainer>
			</Label>
		</Container>
	);
};

// styled components

const StyledHeader = styled(Header)`

	@media (max-width: ${p => p.theme.media.lg}){
		font-size: 1.6vw;	
    }

    @media (min-width: ${p => p.theme.media.lg}){
		font-size: 	1.5rem;
    }		

`;

const Container = styled.div`
	position: absolute;
	box-sizing: border-box;
	background: white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;

	@media (max-width: ${p => p.theme.media.lg}){
        right: ${p => p.theme.whitespace.sm};
        top: ${p => p.theme.whitespace.sm};
		padding: 5px;

		width: 200px;
		height: 120px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		top: ${p => p.theme.whitespace.lg};
        right: ${p => p.theme.whitespace.lg};
		width: 300px;
		height: 180px;
		padding: 10px;

    }

	z-index:-1;
`;

const Label = styled.div`

	position: relative;

	width: 100%;

	flex-grow:  1;

	background-image: url(${meterlabels});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;	

`;

const IndicatorContainer = styled.div<{state?:number}>`
	position: absolute;
	bottom: 0;
	left:50%;
	transform: translate(-50%);	
	width: 20%;
	height: 70%;

	${p => p.state === 1 ? 

		css`
		 
		 & > div {
			transform: rotate(-25deg);
		 }

		`
		: 

		p.state === 2 ?
			css`

			& > div {
				transform: rotate(25deg);
			}

			`

			:

			p.state === 3 ?
				css`

			& > div {
				transform: rotate(85deg);
			}

			`
				:
				css`
		
		& > div {
			transform: rotate(-90deg);
		}

		`
}
`;

const Indicator = styled.div`
	height: 100%;
	width: 100%;

	background-image: url(${indicator});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;	

	transform-origin: center 85%;

	transition: transform 1s;

`;
 
export default Meter;