/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { pump_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
  nodes: {
    ['kast_links-White']: THREE.Mesh
    ['kast_links-Metal_03']: THREE.Mesh
    ['knoppen-Metal_04']: THREE.Mesh
    ['kast_rechts-White']: THREE.Mesh
    ['fan-dark_metal']: THREE.Mesh
    ['radiator-Metal']: THREE.Mesh
    ['steun-Metal_03']: THREE.Mesh
    ['cylinder-Metal']: THREE.Mesh
  }
  materials: {
    ['White.002']: THREE.MeshStandardMaterial
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Warmtepomp_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(pump_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);

	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<group >
				<mesh geometry={nodes['kast_links-White'].geometry} material={materials['White.002']}  />
				<mesh geometry={nodes['kast_links-Metal_03'].geometry} material={nodes['kast_links-Metal_03'].material} />
			</group>
			<group >
				<mesh
					geometry={nodes['knoppen-Metal_04'].geometry}
					material={nodes['knoppen-Metal_04'].material}					
				/>
			</group>
			<group >
				<mesh
					geometry={nodes['kast_rechts-White'].geometry}
					material={nodes['kast_rechts-White'].material}					
				/>
			</group>
			<group >
				<mesh
					geometry={nodes['fan-dark_metal'].geometry}
					material={nodes['fan-dark_metal'].material}					
				/>
			</group>
			<group >
				<mesh
					geometry={nodes['radiator-Metal'].geometry}
					material={nodes['radiator-Metal'].material}					
				/>
			</group>
			<group >
				<mesh
					geometry={nodes['steun-Metal_03'].geometry}
					material={nodes['steun-Metal_03'].material}					
				/>
			</group>
			<group >
				<mesh
					name="cylinder-Metal"
					geometry={nodes['cylinder-Metal'].geometry}
					material={nodes['cylinder-Metal'].material}			
				/>
			</group>
		</group>
	);
}

useGLTF.preload(pump_upgrade_m);
