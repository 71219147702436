import { town, town0, town1, town2 } from 'assets/images';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

 
const Backdrop = () => {

	const {building, gameState} = useGlobalContext();

	useEffect(() => {
		//
	}, [building]);

	return ( 
		<Container>
			<StandardImage/>
			<Image visible={gameState === 3} level={building}/>
			<Darken/>
		</Container>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	height: 100vh;
	width: 100vw;

	z-index: 0;
`;

const Image = styled.div<{level?:number, visible: boolean}>`
	position: absolute;
	left:0;
	top:0;

	background-image: url(${p => p.level !== undefined && backdrops[p.level]});

	opacity: ${p => p.visible ? 1 : 0};
	
	background-size: cover;
	background-repeat: no-repeat;
	filter: blur(5px);
	height: 100%;
	width: 100%;

	transition: opacity 1s;
`; 

const StandardImage = styled.div`
	position: absolute;
	left:0;
	top:0;

	background-image: url(${town});
	background-size:cover;
	filter: blur(5px);
	height: 100%;
	width: 100%;

	transition: opacity 1s;
`; 

const Darken = styled.div`
	position: absolute;
	background-color: rgba(0,0,0,0.5);
	height: 100%;
	width: 100%;
`;

// data

const backdrops = [
	town0,
	town1,
	town2
];
 
export default Backdrop;