/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { zwembad_roof } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    pool_roof: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'pool_roof_open'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(zwembad_roof) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['pool_roof_open'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['pool_roof_open'].reset();
		}
	},[props]);

	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				name="pool_roof"
				castShadow
				receiveShadow
				geometry={nodes.pool_roof.geometry}
				material={materials.kcs_atlas}
				position={[-0.76, 1.1, -2.02]}
				rotation={[-Math.PI, 0, 0]}
				scale={0.88}
			/>
		</group>
	);
}

useGLTF.preload(zwembad_roof);
