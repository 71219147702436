import { ReactNode } from 'react';
import styled from 'styled-components';

type FrameProps = {
    children : ReactNode;
    overflow?: string;
    style?
}

const Frame = (props: FrameProps) => {
	return(
		<Container style={props.style} overflow={props.overflow ? props.overflow : ''}>
			{props.children}
		</Container>
	);
};

// styled components

const Container = styled.div<{overflow:string}>`

    overflow: ${p => p.overflow ? p.overflow : ''};

    position:absolute;
    display:flex;
    flex-direction: column;
    align-items: flex-start;

    left:0;
    top:0;
    bottom:0;
    right:50%;

    background-color: ${p=>p.theme.colors.primary};

    @media (max-width: ${p => p.theme.media.lg}){
        margin: ${p => p.theme.whitespace.sm};
        padding: ${p => p.theme.whitespace.sm};
        border: solid ${p => p.theme.borderSize.sm} ${p=>p.theme.colors.secondary};
    }

    @media (min-width: ${p => p.theme.media.lg}){
        margin: ${p => p.theme.whitespace.lg};
        padding: ${p => p.theme.whitespace.lg};
        border: solid ${p => p.theme.borderSize.lg} ${p=>p.theme.colors.secondary};
    }
	
    border-radius: 20px;

`;

export default Frame;