const cameraPositions = [

	// [azimuth angle, polar angle]
	[4,4],
	[0.63,0.70],
	[-0.81, 0.8],
	[2.27, 1.11],
	[1.11, 2.27],

];

export default cameraPositions;