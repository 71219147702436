import { scenes } from 'components/Three/scenes';
import { useThreeContext } from 'context/three';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsoleLabel, ConsoleRow } from './components';

const ConsoleSceneSelect = () => {

	const { t } = useTranslation();

	const { activeScene, setActiveScene} = useThreeContext(); 
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const [dropdownItems, setDropdownItems] = useState<string[]>();

	
	const fillDropdownItems = () => {
		const a: string[] = [];
		for (const [key] of Object.entries(scenes)) {
			a.push(key);
		}

		setDropdownItems(a);

	};
	
	useEffect(() => {
		fillDropdownItems();
	}, []);
	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.5')}</ConsoleLabel>
			<select onChange={(e) => setActiveScene && setActiveScene(e.target.value)} ref={selectTabRef} value={activeScene}>
				<option label={'none'} value={''}/>
				{dropdownItems && dropdownItems.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleSceneSelect;