import { Box } from '@react-three/drei';
import { useThreeContext } from 'context/three';
import TestModel from '../objects/testing/Test_01';
import Test4 from '../objects/testing/Test_04';
import Battery_upgrade from '../objects/Upgrades/Battery_01';
import Warmte_vries_cointainer_upgrade from '../objects/Upgrades/Container_warmte-koud-opslag_upgrade';
import Ecobarrel_upgrade from '../objects/Upgrades/Ecovat_upgrade';
import Warmte_vries_upgrade from '../objects/Upgrades/Icecube__warmte-koud-opslag_upgrade';
import Led_upgrade from '../objects/Upgrades/Led_upgrade';
import Zwembadcover_upgrade from '../objects/Upgrades/Pool_cover_upgrade';
import Dakisolatie_upgrrade from '../objects/Upgrades/Roofisolation_01';
import Solarpanels_upgrade from '../objects/Upgrades/Solarpanels';
import Solarparking_upgrade from '../objects/Upgrades/Solarparking';
import Warmtepanelen_upgrade from '../objects/Upgrades/Warmtepaneel_upgrade';
import Warmtepomp_upgrade from '../objects/Upgrades/Warmtepomp_upgrade';
import Windturbine_upgrade from '../objects/Upgrades/Windturbine_upgrade';

const Scene = () => {
	
	const {activeUpgrade} = useThreeContext();

	return(
		<group rotation={[0, 2, 0]}>	
			{/* <mesh>
				<boxGeometry args={[1, 1, 1]} />
				<meshStandardMaterial color={'hotpink'} />
			</mesh> */}
			<Warmte_vries_upgrade upgraded={activeUpgrade && activeUpgrade[0]}/>
			<Warmte_vries_cointainer_upgrade upgraded={activeUpgrade && activeUpgrade[0]}/>

		</group>  
	);
};

export default Scene;