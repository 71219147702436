import styled from 'styled-components';

const Button = styled.button`

    border-radius: 50px;
    outline: none;

    background-color: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.blue};

    // Typography

    font-weight: 900;

    box-shadow: 0 0 0.2rem black;

	padding: 0.5vw 1vw;

    transition: all 0.2s;
    pointer-events: all;

    cursor: pointer;

    &:hover, :focus {
        background-color: ${p => p.theme.colors.blue};
        color:${p => p.theme.colors.primary};
        transform: translateY(-5px);
        box-shadow: 0 5px 0.5rem black;
    }

    &:active{
        transform: translateY(0);
        box-shadow: 0 0 0.2rem black;
    }

    &:disabled{
        background-color: grey;
        pointer-events: none;
        color:black;
        border: none;
    }

    @media (max-width: ${p => p.theme.media.lg}){
        border: solid ${p => p.theme.borderSize.sm} ${p=>p.theme.colors.blue};
        font-size: 1;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        border: solid ${p => p.theme.borderSize.lg} ${p=>p.theme.colors.blue};
        font-size: 1.2rem;
    }

`;

export { Button };