import {  Plane, useHelper, useTexture} from '@react-three/drei';
import { MeshProps, SpriteProps, useFrame, useLoader, useThree } from '@react-three/fiber';
import { inpo_0_hover, inpo_0_normal, inpo_1_hover, inpo_1_normal, inpo_2_hover, inpo_2_normal, inpo_s_hover, inpo_s_normal } from 'assets/icons';
import { useGlobalContext } from 'context/global';
import { Category } from 'data/scenes';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';

const scale = 0.7;

const InteractivePoint = (props:{data:Category, category:number, amountCompleted:number}) => {

	const { setActiveCategory } = useGlobalContext();

	const textures = useTexture({normal_0: inpo_0_normal, hover_0: inpo_0_hover, normal_1: inpo_1_normal, hover_1: inpo_1_hover, normal_2: inpo_2_normal, hover_2: inpo_2_hover, normal_s:  inpo_s_normal, hover_s:  inpo_s_hover});

	const [state, setState] = useState<string>('normal');

	const {camera} = useThree();

	useFrame(() => {
		if(pole.current){
			pole.current.lookAt(camera.position.x, pole.current.position.y, camera.position.z);
		}
	});


	const pole = useRef<MeshProps | undefined>();
	const ref = useRef<SpriteProps | undefined>();


	return (  
		<>
			<sprite ref={ref}
				position={[props.data.buttonPosition[0], props.data.buttonPosition[1], props.data.buttonPosition[2]]} 
				scale={[1 * scale, 1 * scale, 0 * scale]}
				onPointerEnter={() => setState('hover')} 
				onPointerLeave={() => setState('normal')}
				onClick={() => {setActiveCategory && setActiveCategory(props.category);}}
				transparent
			>
				{ state === 'normal' ?
					<> 
						<spriteMaterial map={textures[`normal_${props.amountCompleted}`]} />
					</>
					:
					<>
						<spriteMaterial map={textures[`hover_${props.amountCompleted}`]} />
					</>
				
				}

			</sprite>
			<mesh ref={pole} position={[props.data.buttonPosition[0], props.data.buttonPosition[1] - 0.48, props.data.buttonPosition[2]]} >
				<planeBufferGeometry attach="geometry" args={[0.045, 0.7]}/>
				{/* <boxGeometry args={[5, 1, 1]}/> */}

				{ state === 'normal' ?
					<> 
						<meshBasicMaterial attach="material" map={textures.hover_s} transparent/>
					</>
					:
					<>
						<meshBasicMaterial attach="material" map={textures.hover_s} transparent/>
					</>
				
				}
			</mesh>
		</>
	);
};

// styled components

const Label = styled.div`
	background: green;
	tran
`;

 
export default InteractivePoint;