import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import { Suspense } from 'react';
import WSI from '../WSI';
import  Scene01 from './Scene01';
import  Scene02 from './Scene02';
import  Scene03 from './Scene03';
import  Test from './Test';


export const scenes = {
	'scene01' : Scene01,
	'scene02' : Scene02,
	'scene03' : Scene03,
	'test' : Test,
};

const Scene = () => {

	const {gameState} = useGlobalContext();
	const {activeScene} = useThreeContext();
	const CurrentScene = activeScene ? scenes[activeScene] : null;

	//Management functionaliteiten

	return (
		<Suspense fallback={true}>
			{ CurrentScene && <CurrentScene/>}
			<WSI/>
		</Suspense>
	);

};


export default Scene;
