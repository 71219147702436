/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { sporthal } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    base_ground_sportshall: THREE.Mesh
    sportshall: THREE.Mesh
    grass_sporthals: THREE.Mesh
    rainpipe001: THREE.Mesh
    flora: THREE.Mesh
    fences: THREE.Mesh
    bikerack: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

export default function Sporthal({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(sporthal) as GLTFResult;
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.base_ground_sportshall.geometry}
				material={nodes.base_ground_sportshall.material}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.sportshall.geometry}
				material={nodes.sportshall.material}
				position={[0.02, 0.63, -0.69]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.grass_sporthals.geometry}
				material={nodes.grass_sporthals.material}
				position={[0, 0.04, 0]}
				scale={[1, 0.51, 1]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.rainpipe001.geometry}
				material={nodes.rainpipe001.material}
				position={[0.35, 0.52, 0.01]}
				scale={0.88}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.flora.geometry}
				material={nodes.flora.material}
				position={[1.62, 0.06, -2.09]}
				rotation={[0, 0.17, 0]}
				scale={0.9}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.fences.geometry}
				material={nodes.fences.material}
				position={[-0.56, 0.18, 0.87]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.bikerack.geometry}
				material={nodes.bikerack.material}
				position={[1.16, 0.11, -0.07]}
			/>
		</group>
	);
}

useGLTF.preload(sporthal);
