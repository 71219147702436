import { OrbitControls, OrbitControlsProps } from '@react-three/drei';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { contextProps, threeContextProps } from './types';

const ThreeContext = createContext<Partial<threeContextProps>>({});

const ThreeContextProvider = (props: contextProps) => {

	//#region useStates

	const controls = useRef();
	
	const setControls = (newRef) => {
		controls.current = newRef;
	};
	
	const [ activeScene, setAS ] = useState<string>('');
	const [ chosenScene, setChosenScene] = useState<string>('');

	const setActiveScene = (i: string) => {
		if(activeScene !== i){
			setActiveUpgrade([false, false, false, false, false, false, false, false, false]);
			setAS(i);
		}
	};

	const [ zoom, setZoom] = useState<number>(5);

	const [ activeUpgrade, setActiveUpgrade] = useState<boolean[]>([false, false, false, false, false, false, false, false, false]);

	//#endregion

	//#region use effects

	useEffect(()=>{
		setActiveUpgrade([false, false, false, false, false, false, false, false, false]);
	},[activeScene]);

	useEffect(()=>{
		//
	},[activeUpgrade]);

	//#endregion

	//#region Passed Props

	const passedFunctions = {
		controls,
		activeScene,
		chosenScene,
		zoom,
		activeUpgrade,
		
	};

	const passedValues = {
		setControls,
		setActiveScene,		
		setChosenScene,
		setZoom,
		setActiveUpgrade,
	};

	//#endregion

	//#render

	return (
		<ThreeContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</ThreeContext.Provider>
	);

	//#endregion
};

const useThreeContext = () => useContext(ThreeContext);

export {ThreeContextProvider, ThreeContext, useThreeContext};
