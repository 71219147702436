import React, {useState, useRef, ReactComponentElement, ReactNode, useEffect} from 'react';
import { useGlobalContext } from 'context/global/index';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import ConsoleSceneSelect from './SceneSelect';
import ConsoleGameState from './GameState';
import ConsoleUpgradeSelect from './UpgradeSelect';
import ConsoleCameraControls from './ConsoleCameraControls';

const tabs = {
	'scene' : ConsoleSceneSelect,
	'game state' : ConsoleGameState,
	'upgrades' : ConsoleUpgradeSelect,
	'cameracontrols': ConsoleCameraControls,
};

const Console = () => {
	const {displayConsole} = useGlobalContext();
	const { t } = useTranslation();
	
	const [activeInterfaceItem, setActiveInterfaceItem] = useState('scene');
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const [tabLabels, setTabLabels] = useState<string[] | undefined>();

	useEffect(() => {
		if(!tabLabels){
			const labels: string[] = [];
			for (const [key] of Object.entries(tabs)) {
				labels.push(key);
			}
			setTabLabels(labels);
		}
	}, []);
	
	// const [ActiveTab, setActiveTab] = useState<ReactNode | undefined>();

	const ActiveTab = tabs[activeInterfaceItem];

	// Render Function
	return (
		<> 
			{displayConsole &&
				<ConsoleContainer>
					<ConsoleWrap>

						<ConsoleTitle>{t('general.1')}</ConsoleTitle>

						<select onChange={(e) => setActiveInterfaceItem(e.target.value)} ref={selectTabRef} value={activeInterfaceItem}>
							{tabLabels && tabLabels.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
						</select>
				
						{ ActiveTab &&
							<ActiveTab/>
						}

					</ConsoleWrap>
				</ConsoleContainer>
			}
		</>
	);
	
};


//#region styled components
const ConsoleTitle = styled.div`
	font-size: 1.5rem;
	font-weight: bold;
`;

const ConsoleContainer = styled.div`
    z-index: 10;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    justify-content: center;
	width:100vw;
	text-align: left;
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
`;

//#endregion

export default Console;

