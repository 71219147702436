import { useThree } from '@react-three/fiber';
import { Button } from 'components/Buttons';
import Typing from 'components/Text/Typing';
import { Header, Header2, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import advisors from 'data/advisors';
import scenes from 'data/scenes';
import upgrades, { Upgrade as UpgradeProps } from 'data/upgrades';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import Frame from '../components/Frame';
import Advisor from './Advisor';
import Upgrade from './Upgrade';

interface UpgradesProps {
    active:boolean;
    setActive: (b:boolean) => void;
}
 
const Upgrades = (props:UpgradesProps) => {

	const { activeCategory, categoriesCompleted, setCategoriesCompleted, advisor, setCameraPosition, setCameraPosReached } = useGlobalContext();
	const { activeScene, setActiveUpgrade, activeUpgrade } = useThreeContext();

	const {t} = useTranslation();

	const [title, setTitle] = useState<string>();
	const [selected, setSelected ] = useState<number | undefined>();

	useEffect(() => {
		if(t && activeCategory !== undefined){
			setTitle(t(`categories.${activeCategory}`));
		}

		if(!props.active){
			setSelected(undefined);
		}
	},[props.active]);

	const [upgradeData, setUpgradeData] = useState<UpgradeProps[]>();

	useEffect(() => {
		if(activeScene && activeCategory !== undefined){
			const ug : UpgradeProps[] = [];
			scenes[activeScene]?.categories[activeCategory].upgrades.map((value) => {
				const upgrade = upgrades[value] ? upgrades[value] : null;
				if(upgrade){
					ug.push(upgrade);
				}
			});
			setUpgradeData(ug);
		}    
	},[activeCategory]);

	const activateUpgrade = (i: number | undefined) => {

		if(i === undefined){
			return;
		}

		// Update Category progression
		if(categoriesCompleted && activeCategory !== undefined){
			const categories = [...categoriesCompleted];
			categories[activeCategory] = true;
			setCategoriesCompleted && setCategoriesCompleted(categories);
		}

		
		const flubbel = activeUpgrade ? [...activeUpgrade] : null;
		if(flubbel){
			flubbel[i] = true;
			if(upgradeData && selected !== undefined){
				setCameraPosition && setCameraPosition(upgradeData[selected].cameraPos, () => setActiveUpgrade && setActiveUpgrade(flubbel));
			}
		} 
		props.setActive(false);
	};

	return ( 
		<Container active={props.active}>
			{/* { upgradeData && <PositionedButton disabled={selected === undefined ? true : false} onClick={() => selected !== undefined && activateUpgrade(upgradeData[selected].model)}>Ik kies deze</PositionedButton>}	 */}
			<HeaderRow>
				<CustomHeader style={{color: 'white'}}>Kies een verduurzamingsoptie</CustomHeader>
				<Close onClick={() => {props.setActive(false);}}>X</Close>
			</HeaderRow>
			<UpgradeRow>
				<CustomHeader>{title}</CustomHeader><br/>
				<UpgradesContainer>
					{ upgradeData && upgradeData.map((value, index) => 
						<Upgrade activateUpgrade={() => selected !== undefined && activateUpgrade( upgradeData[selected].model)} selected={selected} index={index} onClick={(e) => {e.currentTarget.blur(); setSelected((a) => a === index ? undefined : index);}} key={`upgrade-button-${index}`}/>
						// <UpgradeComponent onClick={(e) => {e.currentTarget.blur(); setSelected(index);}} key={`upgrade-button-${index}`} selected={index === selected ? true : false}>
						// 	<CustomHeader2>{t(`game.upgrades.${activeScene}.category${activeCategory}.upgrade${index}.title`)}</CustomHeader2>
						// 	<CustomParagraph>{t(`game.upgrades.${activeScene}.category${activeCategory}.upgrade${index}.description`)}</CustomParagraph>
						// </UpgradeComponent>
					)}
				</UpgradesContainer>
			</UpgradeRow>
			<AdvisorRow>
				<Advisor selected={selected}/>
			</AdvisorRow>
		</Container>
	);
};

// keyframes

const SlideIn = keyframes`
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
    }
`;

const SlideOut = keyframes`
    100%{
        transform: translateX(-100%);
    }
    0%{
        transform: translateX(0);
    }
`;

// styled components

const Container = styled.div<{active:boolean}>`

	display: flex; flex-direction: column;

    height: 100%;
    width: 50%;


	box-sizing: border-box;

    animation: ${SlideIn} 1s forwards;
    
    ${ p => !p.active && css`
        animation: ${SlideOut} 1s forwards;
    `}

    pointer-events: all;
	user-select: none;

	@media (max-width: ${p => p.theme.media.lg}){
		padding: ${p => p.theme.whitespace.sm} 0 ${p => p.theme.whitespace.sm} ${p => p.theme.whitespace.sm};
		&> * + * {
			margin-top: 10px;
		}
    }

    @media (min-width: ${p => p.theme.media.lg}){
        padding: ${p => p.theme.whitespace.lg} 0 ${p => p.theme.whitespace.lg} ${p => p.theme.whitespace.lg};
		&> * + * {
			margin-top: 20px;
		}
    }

`;

//#region rows

const HeaderRow =  styled.div`
	display:flex;
	align-items: center;
`; 


const UpgradeRow = styled.div`
	flex-grow: 1;
	min-height: 0;
	display: flex; flex-direction: column;
	background: white;
	border-radius: 20px;

	/* width: 100%; */
	/* height: fit-content; */

	box-sizing: border-box;

	@media (max-width: ${p => p.theme.media.lg}){
        padding: ${p => p.theme.whitespace.sm};
    }

    @media (min-width: ${p => p.theme.media.lg}){
        padding: ${p => p.theme.whitespace.lg};
    }

`;

const AdvisorRow = styled.div`
	display: flex;

	background: #DFEBF7;
	border-radius: 20px;

	@media (max-width: ${p => p.theme.media.lg}){
		padding: ${p => p.theme.whitespace.sm};
		height: 70px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		padding: ${p => p.theme.whitespace.lg};
		height: 170px;
    }
`;

// #endregion


// Upgrades

const UpgradesContainer = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	flex-grow: 1;
	display: flex; flex-direction: column;
	
	@media (max-width: ${p => p.theme.media.lg}){
		gap: 5px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		gap: 10px;
    }

`;

const Close = styled(Button)`

	display:flex;
	align-items: center;
	justify-content:center;
	border-radius: 100%;

	margin-left: auto;
	height: 0;
	width: 0;

	@media (max-width: ${p => p.theme.media.lg}){
		padding: 15px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		padding: 20px;
    }

`;

const CustomHeader = styled(Header)`

	margin: 0;

	/* color: white; */

    @media (max-width: ${p => p.theme.media.lg}){
        font-size: 2vw;
		/* margin-left: ${p => p.theme.whitespace.sm} */
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.7rem;
		/* margin-left: ${p => p.theme.whitespace.lg} */
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 2rem;
		/* margin-left: ${p => p.theme.whitespace.xl} */
    }

`;




 
export default Upgrades;