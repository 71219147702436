import { Canvas } from '@react-three/fiber';
import { Controls, Lighting } from 'components/Three';
import { ReactNode, Suspense } from 'react';
import {ResizeObserver} from '@juggle/resize-observer';


type BuildingPreviewProps = {
    children: ReactNode;
}

const BuildingPreview  = (props: BuildingPreviewProps) => {
	return ( 
		<Canvas shadows={true} resize={{polyfill: ResizeObserver}}>
			<Controls/>
			<Lighting/>
			{props.children}
		</Canvas>
	);
};
 
export default BuildingPreview;