import { arrowdown_normal } from 'assets/icons';

export type Upgrade = {
	model: number;
	image: string;
	impact?: number;
	cameraPos: number;
}
type UpgradeRecord = {
	[Key in string]?:Upgrade;
}

const upgrades : UpgradeRecord = {
	//#region zwembad
	scene01_cat1_upgrade01 : {
		model: 0,
		image: arrowdown_normal,
		cameraPos: 1,
	},
	scene01_cat1_upgrade02 : {
		model: 1,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene01_cat1_upgrade03 : {
		model: 2,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene01_cat2_upgrade01 : {
		model: 3,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene01_cat2_upgrade02 : {
		model: 4,
		image: arrowdown_normal,
		cameraPos: 3,
	},
	scene01_cat3_upgrade01 : {
		model: 5,
		image: arrowdown_normal,
		cameraPos: 4,
	},
	scene01_cat3_upgrade02 : {
		model: 6,
		image: arrowdown_normal,
		cameraPos: 4,
	},
	scene01_cat3_upgrade03 : {
		model: 7,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	
	//#endregion

	//#region sporthal
	scene02_cat1_upgrade01 : {
		model: 0,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene02_cat1_upgrade02 : {
		model: 1,
		image: arrowdown_normal,
		cameraPos: 3,
	},
	scene02_cat1_upgrade03 : {
		model: 2,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene02_cat2_upgrade01 : {
		model: 3,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene02_cat2_upgrade02 : {
		model: 4,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene02_cat2_upgrade03 : {
		model: 5,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene02_cat3_upgrade01 : {
		model: 6,
		image: arrowdown_normal,
		cameraPos: 4,
	},
	scene02_cat3_upgrade02 : {
		model: 7,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	//#endregion

	//#region tennisclub
	scene03_cat1_upgrade01 : {
		model: 0,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene03_cat1_upgrade02 : {
		model: 1,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene03_cat1_upgrade03 : {
		model: 2,
		image: arrowdown_normal,
		cameraPos: 1,
	},
	scene03_cat2_upgrade01 : {
		model: 3,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene03_cat2_upgrade02 : {
		model: 4,
		image: arrowdown_normal,
		cameraPos: 5,
	},
	scene03_cat2_upgrade03 : {
		model: 5,
		image: arrowdown_normal,
		cameraPos: 2,
	},
	scene03_cat3_upgrade01 : {
		model: 6,
		image: arrowdown_normal,
		cameraPos: 4,
	},
	scene03_cat3_upgrade02 : {
		model: 7,
		image: arrowdown_normal,
		cameraPos: 4,
	}
	//#endregion
};		

export default upgrades;
