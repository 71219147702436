import { Button } from 'components/Buttons';
import { scenes } from 'components/Three/scenes';
import { Header, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import { Suspense, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Meter from './Meter';
import BuildingPreview from '../Selection/components/BuildingPreview';
import Upgrades from './Upgrades';
import Sporthal_roof from 'components/Three/objects/Sporthal/Sportshall_roof';
import Tennisbaan_roof from 'components/Three/objects/Tennisbaan/Tenniscourt_roof';
import Sporthal from 'components/Three/objects/Sporthal/Sporthal';
import Tennisbaan from 'components/Three/objects/Tennisbaan/Tennisbaan';
import Zwembad from 'components/Three/objects/Zwembad/Zwembad';
import Zwembad_roof from 'components/Three/objects/Zwembad/Swimmingpool_roof';
import { StyledParagraph } from '../Selection';

const Game  = () => {

	const [state, setState] = useState<number>(0); // dictates wether or not the screen is in an intro/outro animation. 
	const [upgradesOpened, setUpgradesOpened] = useState<boolean>(false);
	const [gameComplete, setGameComplete] = useState<boolean>(false);
	const { setGameState , gameState , building, upgradePanelActive, tutorial, setTutorial, setUpgradePanelActive, activeCategory, categoriesCompleted, cameraPos } = useGlobalContext();
	const { setActiveScene, activeScene, setChosenScene } = useThreeContext();
	const [showEndButton, setShowEndButton] = useState<boolean>(false);
	const [amountCompleted , setAmountCompleted] = useState<number>(0);


	useEffect(() => {
		if(activeCategory !== undefined){
			setUpgradePanelActive && setUpgradePanelActive(true);
		}
		else if(upgradePanelActive){
			setUpgradePanelActive && setUpgradePanelActive(false);
		}
	},[activeCategory]);

	useEffect(() => {
		if(upgradePanelActive && !upgradesOpened){
			setUpgradesOpened(true);
		}
	},[upgradePanelActive]);

	// Game Done Check
	useEffect(() => {
		if(categoriesCompleted){
			let completed = 0;
			categoriesCompleted.map((value) => {
				if(value)
					completed++;
			});
			if(completed == 3){
				setShowEndButton(true);
			}
			setAmountCompleted(completed);
		}
	}, [categoriesCompleted]);

	const onEndGame = () => {
		setGameState && gameState && setGameState(gameState + 1);	
		setActiveScene && setActiveScene('');
	};

	//#region Initialization
	const [initialized, setInitialized] = useState<boolean>(false); // Determines wether the mount useEffect has been triggered.
	const buildings = Object.keys(scenes);

	useEffect(() => {
		setTutorial &&setTutorial(true);
		if(!initialized){
			// set scene active
			setActiveScene && building !== undefined && setActiveScene(buildings[building]);
			setChosenScene && building !== undefined && setChosenScene(buildings[building]);
			setInitialized(true);
			setTimeout(() => setState(1), 1000);
		}
	}, []);

	const closeTutorial = () => {
		setTutorial && setTutorial(false);
	};
	
	const BuildingComponents = {
		'scene01': Zwembad,
		'scene02': Sporthal,
		'scene03': Tennisbaan,
	};

	const RoofComponents = {
		'scene01': Zwembad_roof,
		'scene02': Sporthal_roof,
		'scene03': Tennisbaan_roof,
	};

	const BuildingComponent = activeScene ? BuildingComponents[activeScene] : <></>;				
	const RoofComponent = activeScene ? RoofComponents[activeScene] : <></>;
	const RoofPosition = activeScene ? RoofComponents[activeScene] == Zwembad_roof ? [0,0.6,0] : [0,0,0] : [];
	
	//#endregion
	return (
		<>
			<Container className={state === 0 ? 'fade-in' : state === 2 ? 'fade-out' : ''}>	
				<Meter amountCompleted={amountCompleted}/>		
				{tutorial && <>				
					<Container2/>
					<Wrapper>
						<NewFrame>
							<Header>Instructie</Header>
							<StyledParagraph style={{marginTop: '0px', marginBottom: '0px'}}>
								Je kunt het model draaien met je muis of je vinger. Op deze manier kun je ook in- en uitzoomen. Je gaat drie maatregelen doorvoeren: energie besparen, opwekken en opslaan. Kies een optie door op het blauwe icoon te klikken.					
							</StyledParagraph>
						</NewFrame>	
						<Suspense fallback={<span>loading...</span>}>
							<CanvasWrapper>
								<BuildingPreview>
									<BuildingComponent position={[0,0,0]}/>
									<RoofComponent position={RoofPosition}/>
								</BuildingPreview>
							</CanvasWrapper>
						</Suspense>						
						<CloseTutorial onClick={closeTutorial}>Verder</CloseTutorial>
					</Wrapper></>}
				{
				}
				{ upgradesOpened && 
				<Upgrades active={upgradePanelActive ? true : false} setActive={(b : boolean) => {setUpgradePanelActive && setUpgradePanelActive(b);}}/>
				}

				{showEndButton && !cameraPos &&
					<EndButtonContainer>
						<EndButton onClick={onEndGame}>Rond spel af</EndButton>
					</EndButtonContainer>
				}
			</Container>
		</>
	);
};

// keyframes
const fadeOut = keyframes`
	0% {
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
`;

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
`;

// style components
const CanvasWrapper = styled.div`
	height: 50%;
	width: 100%;
	pointer-events: all;

`;
 
const EndButton = styled(Button)`
	margin: 20px;
	cursor: pointer;
	pointer-events: all;

`;

const EndButtonContainer = styled.div`

	position: absolute;
	left: 50%;
	bottom:10%;

	transform: translateX(-50%);

`;

const CloseTutorial = styled(Button)`
	//margin: 20px;
	cursor: pointer;
	pointer-events: all;

`;

const Container = styled.div`

	height: 100%;
	width: 100%;

	&.fade-out{
		animation: ${fadeOut} 1s forwards; 
	}

	&.fade-in{
		animation: ${fadeIn} 1s forwards; 
	}

	pointer-events: none!important;

`;
const NewFrame = styled.div`
	width: 96%;
    display:flex;
	justify-content: center;
    flex-direction: column;


	text-align: center;
    background-color: ${p=>p.theme.colors.primary};

	padding: 2%;
	
    border-radius: 20px;
`;

const Container2 = styled(Container)`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.7;
`;

const Wrapper = styled(Container)`
    position:absolute;
    left:50%;
    top:50%;

	width: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;
`;
 
 
export default Game;
