import { ContactShadows, Shadow, useHelper } from '@react-three/drei';
import { useThree, useFrame, Object3DNode, DirectionalLightProps} from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { useThreeContext } from 'context/three';

const Lighting = () => {

	const dl = useRef<DirectionalLightProps>();
    
	return(
		<>
			<ambientLight intensity={0.7} />
			<directionalLight ref={dl} color="#ffecb8" position={[5,15,-5]} castShadow intensity={1}
				shadow-mapSize-width={4096}
				shadow-mapSize-height={4096}
				shadow-bias={-0.00004}
			/>
		</>
	);
};

export default Lighting;