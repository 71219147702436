import { arrow } from 'assets/icons';
import { Button } from 'components/Buttons';
import { Header, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

interface UpgradeProps {
	selected: number | undefined;
	key: React.Key;
	onClick: (e: any) => void;
	index: number;
	activateUpgrade: () => void;
}
 
const Upgrade = (props : UpgradeProps) => {

	const { activeCategory } = useGlobalContext();
	const {activeScene} = useThreeContext();
	const {t} = useTranslation();

	return ( 
		<Container selected={props.selected !== undefined && props.selected === props.index ? true :  false} onClick={(e) => props.onClick(e)}>
			<HeaderRow>
				<StyledHeader>
					{t(`game.upgrades.${activeScene}.category${activeCategory}.upgrade${props.index}.title`)}
				</StyledHeader>
				<Arrow selected={props.selected !== undefined && props.selected === props.index ? true :  false}><div/></Arrow>
			</HeaderRow>
			
			{ props.selected !== undefined && props.index == props.selected && 
				<SelectedRow>
					<StyledParagraph>{t(`game.upgrades.${activeScene}.category${activeCategory}.upgrade${props.index}.description`)}</StyledParagraph>
					<StyledButton onClick={() => props.activateUpgrade()} style={{marginLeft: 'auto'}}>Ik kies deze!</StyledButton>
				</SelectedRow>
			}
		</Container>
	);
};

// styled components

const Container = styled.div<{selected:boolean}>`

	${ p => p.selected && css`
		/* background: green; */
	`}

	border-radius: 10px;
	border: solid 2.5px ${p=>p.theme.colors.secondary};

	@media (max-width: ${p => p.theme.media.lg}){
		padding: 5px 10px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		padding: 10px 20px;
    }

`;

const StyledHeader = styled(Header)`
	color: black;
	margin:0;
	/* margin-right: 10px; */

	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 17px;
		
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.5rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.8rem;
    }
`;

const StyledParagraph = styled(Paragraph)`
	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.3rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.5rem;
    }
`;

const HeaderRow = styled.div`

	display: flex;

`;

const SelectedRow = styled.div`

	display: flex; flex-direction: column;

`;

const test = keyframes`from {display:none;} to {display:block}`;

const Arrow =  styled.div<{selected: boolean}>`

	position : relative;
	margin-left: auto;
	
	${p => p.selected && css`
		& > div {
			transform: rotate(180deg) translateX(-100%)!important;
		}
	`}

	/* background-color: green; */
	/* outline: solid 2px green; */

	/* transition: transform 0.3s; */

	& > div {

		transform: rotate(0deg) translateX(-100%);

		position: absolute;
		left:0;
		top:0;

		background-image:url(${arrow});
		background-size: 100%;

		height: 100%;
		aspect-ratio: 1/1;
		/* background-color :red; */

		transition: transform 0.3s;
		transform-origin: -50% 50%;

	}


`;

const StyledButton = styled(Button)`

	padding: 0.5vw 1vw;

	background-color: ${p => p.theme.colors.blue};
	color: white;

	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.2rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.4rem;
    }
`;
 
export default Upgrade;