import Background from 'components/Background';
import { useGlobalContext } from 'context/global';
import styled from 'styled-components';
import End from './End';
import Game from './Game';
import Introduction  from './Introduction';
import Result from './Result';
import Selection from './Selection';

const screens = [
	Introduction,
	Selection,
	Game,
	//Result,
	End,
];

export {screens};
 
const Screens = () => {

	const { gameState } = useGlobalContext();

	const Screen =   gameState ?  screens[gameState - 1] : null;

	return (
		<Container>
			{ Screen && <Screen/> }
		</Container>
	);

};

// styled components

const Container = styled.div`

	position: relative;
	/* left:0; right:0; */

	width: 100%;
	height: 100%;

	z-index: 2;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}

`;
 
export default Screens;