import { Andrea, Ariean, Celeste, Justin, Lisa } from 'assets/images';

type Advisor = {
	portrait: string,
	name: string,
	job: string,
 }

const advisors: Advisor[] = [
	{
		portrait: Lisa,
		name: 'Lisa',
		job: 'Sportbestuurder'
	},
	{
		portrait: Justin,
		name: 'Justin',
		job: 'Ambtenaar duurzaamheid'
	},
	{
		portrait: Celeste,
		name: 'Celeste',
		job: 'Ambtenaar sport'
	},
	{
		portrait: Ariean,
		name: 'Ariean',
		job: 'Ambtenaar maatschappelijk vastgoed'
	},
	{
		portrait: Andrea,
		name: 'Andrea',
		job: 'Energiecoach'
	},
];
export default advisors;