declare module 'styled-components' {
	export interface DefaultTheme {
		colors: {
			primary: string;
			secondary: string;
			blue: string
			primary_bg: string;
			secondary_bg: string;
			accent_01: string;
		},
		borderSize: {
			sm: string;
			lg: string;	
		};
		media: {
			xs: string,
			sm: string,
			lg: string,
			xl: string,
		}
		whitespace: {
			xs: string,
			sm: string,
			lg: string,
			xl: string,
		}
	}
}

const Theme = {
	colors: {

		// 
		primary: 'white',
		secondary: 'black',
		primary_bg: '#EFEFEF',
		secondary_bg: 'white',
		blue: '#0081D6',
		// Accents
		accent_01: '#C1E1C1',
	},
	media : {
		xs: '400px',
		sm: '600px',
		lg: '1000px',
		xl: '1440px',
	},
	borderSize: {
		sm: '2px',
		lg: '4px',
	},
	whitespace: {
		xs: '5px',
		sm: '10px',
		lg: '50px',
		xl: '50px',
	}
};

export default Theme;