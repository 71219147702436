/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { warmte_vries_cointainer_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
	nodes: {
		['heat-coldstorage_container001']: THREE.Mesh
		['heat-coldstorage_container1']: THREE.Mesh
	}
	materials: {
		kcs_atlas: THREE.MeshStandardMaterial
	}
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Warmte_vries_cointainer_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(warmte_vries_cointainer_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if (actions) {
			for (const action in actions) {
				actions[action].setLoop(THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;
			}
		}
	}, [actions]);

	useEffect(() => {
		if (props.upgraded) {
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	}, [props]);

	return (
		<group visible={props.upgraded? true : false}  ref={group} {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['heat-coldstorage_container001'].geometry}
				material={nodes['heat-coldstorage_container001'].material}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes['heat-coldstorage_container1'].geometry}
				material={nodes['heat-coldstorage_container1'].material}
			/>
		</group>
	);
}

useGLTF.preload(warmte_vries_cointainer_upgrade_m);
