import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

	html{
		height: -webkit-fill-available;
		
	}

	body{
		margin: 0!important;
		padding: 0!important;
		min-height: 100vh;
		min-height: -webkit-fill-available;
		min-width: 100vw;
		min-width: -webkit-fill-available;
		overflow: hidden;
		font-family: 'Lato', sans-serif;
	}
		
	html, body, #root {
  		height: 100vh;
	}

body > * {
		user-select: none;
	}
`;

export default GlobalStyle; 