/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { kunstgras_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
  nodes: {
    pijpen: THREE.Mesh
    pijpen1: THREE.Mesh
    gras: THREE.Mesh
    grond: THREE.Mesh
  }
  materials: {
    ['Metal_04.004']: THREE.MeshStandardMaterial
    kcs_atlas: THREE.MeshStandardMaterial
    //kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Kunstgras_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(kunstgras_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);

	return (
		<group visible={props.upgraded? true : false} position={[0, 0, 0]} ref={group} {...props} dispose={null}>
			<mesh
				geometry={nodes.pijpen.geometry}
				material={materials['Metal_04.004']}
				position={[0, -1.02, 0]}
				rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
				scale={0}
			/>
			<mesh
				geometry={nodes.pijpen1.geometry}
				material={nodes.pijpen1.material}
				position={[-0.4, -1.02, 0]}
				rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
				scale={0}
			/>
			<mesh
				name="gras"
				geometry={nodes.gras.geometry}
				material={nodes.gras.material}
				position={[0, -2, 0]}
				rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
				scale={0}
			/>
			<mesh
				name="grond"
				geometry={nodes.grond.geometry}
				material={nodes.grond.material}
				morphTargetDictionary={nodes.grond.morphTargetDictionary}
				morphTargetInfluences={nodes.grond.morphTargetInfluences}
				rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
				scale={0}
			/>
		</group>
	);
}

useGLTF.preload(kunstgras_upgrade_m);
