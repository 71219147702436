/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { led_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
	nodes: {
		['led_lightbulb-glass_03']: THREE.Mesh
		['led_lightbulb2-White']: THREE.Mesh
		['led_lightbulb1-Metal']: THREE.Mesh
		['led_lightbulb1-White']: THREE.Mesh
	}
	materials: {
		kcs_atlas: THREE.MeshStandardMaterial
		['White.001']: THREE.MeshStandardMaterial
	}
}

type ActionName = 'Anim_0' | 'led_lightbulb.1-WhiteAction'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Led_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(led_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if (actions) {
			for (const action in actions) {
				actions[action].setLoop(THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;
			}
		}
	}, [actions]);

	useEffect(() => {
		if (props.upgraded) {
			actions['Anim_0'].play();
			setTimeout(() => {
				setUpgradeAnimationPlayed(true);
			},4500);
		} else {
			actions['Anim_0'].reset();
			setUpgradeAnimationPlayed(false);
		}
	}, [props]);

	useEffect(() => {
		console.log('hoi');
	},[upgradeAnimationPlayed]);

	return (
		<group visible={upgradeAnimationPlayed ? false : props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<group>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes['led_lightbulb-glass_03'].geometry}
					material={nodes['led_lightbulb-glass_03'].material}
				/>
			</group>
			<group>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes['led_lightbulb2-White'].geometry}
					material={nodes['led_lightbulb2-White'].material}
					
				/>
			</group>
			<group>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes['led_lightbulb1-White'].geometry}
					material={materials['White.001']}
				/>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes['led_lightbulb1-Metal'].geometry}
					material={nodes['led_lightbulb1-Metal'].material}
				/>
			</group>
		</group>
	);
}

useGLTF.preload(led_upgrade_m);
