
import styled from 'styled-components';
import SelectionContainer from '../components/SelectionContainer';

import { sporthalImage, tennisImage, zwembadImage } from 'assets/images';
 
type BuildingSelectionProps = {
	setSelected : (i:number) => void;
}

const BuildingOptions = [
	{image : zwembadImage},
	{image : sporthalImage},
	{image : tennisImage},
];

export {BuildingOptions};

const BuildingSelection = (props: BuildingSelectionProps) => {

	return ( 
		<SelectionContainer setSelected={(i)=>props.setSelected(i)}>
			{ BuildingOptions.map((value, index) => {
				return(
					<BuildingPortrait key={`advisor-selection-portrait-${index}`} img={value.image}/>	
				);
			})}
		</SelectionContainer>
	);
};

// styled components

const BuildingPortrait = styled.div<{img:string}>`

	width:50%;
	padding-top:50%;

	background-image: url(${p => p.img});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

`;
 
export default BuildingSelection;
