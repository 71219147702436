import { useGlobalContext } from 'context/global';
import cameraPositions from 'data/cameraPositions';
import { ConsoleLabel, ConsoleRow } from './components';

const ConsoleCameraControls = () => {

	const { setCameraPosition, cameraPos } = useGlobalContext();

	return (  
		<ConsoleRow>
			<ConsoleLabel>Label</ConsoleLabel>
			<select onChange={(e) => setCameraPosition && setCameraPosition(parseInt(e.target.value))} value={cameraPos}>
				<option label={'0'} value={0}></option>
				{cameraPositions.map((x, index) => (<option key={`console-cameracontrols-option-${index}`} label={String(index + 1)} value={index + 1}/>))}
			</select>
		</ConsoleRow>
	);
};
 
export default ConsoleCameraControls;