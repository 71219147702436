/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { zwembad } from 'assets/models';

type GLTFResult = GLTF & {
  nodes: {
    base_ground: THREE.Mesh
    grass_ground: THREE.Mesh
    swimming_pool: THREE.Mesh
    woodfence: THREE.Mesh
    pool_fence: THREE.Mesh
    Sphere034: THREE.Mesh
    Sphere034_1: THREE.Mesh
    building_swimmingpool: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
    grass_02: THREE.MeshStandardMaterial
  }
}

export default function Zwembad({ ...props }: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>();
	const { nodes, materials } = useGLTF(zwembad) as GLTFResult;
	return (
		<group ref={group} {...props} dispose={null}>
			<mesh geometry={nodes.base_ground.geometry} material={nodes.base_ground.material} />
			<mesh geometry={nodes.grass_ground.geometry} material={nodes.grass_ground.material} position={[0, 0.05, 0]} />
			<mesh
				geometry={nodes.swimming_pool.geometry}
				material={nodes.swimming_pool.material}
				position={[1.67, 0.06, -0.82]}
			/>
			<mesh geometry={nodes.woodfence.geometry} material={nodes.woodfence.material} position={[-1.36, 0.08, 1.16]} />
			<mesh geometry={nodes.pool_fence.geometry} material={nodes.pool_fence.material} position={[-1.16, 0.24, 0.34]} />
			<group position={[2.01, 0.03, 1.59]} rotation={[Math.PI / 2, 0, 2.97]}>
				<mesh geometry={nodes.Sphere034.geometry} material={nodes.Sphere034.material} />
				<mesh geometry={nodes.Sphere034_1.geometry} material={materials.grass_02} />
			</group>
			<mesh
				geometry={nodes.building_swimmingpool.geometry}
				material={nodes.building_swimmingpool.material}
				position={[-0.77, 0.6, -0.89]}
			/>
		</group>
	);
}

useGLTF.preload(zwembad);
