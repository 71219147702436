import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import scenes from 'data/scenes';
import { useEffect, useState } from 'react';
import InteractivePoint from './InteractivePoint';


const WSI = (props) => {

	const {activeScene} = useThreeContext();
	const {upgradePanelActive, categoriesCompleted, showCategories, cameraPos} = useGlobalContext();

	const [amountCompleted , setAmountCompleted] = useState<number>(0);

	useEffect(() => {
		if(categoriesCompleted){
			let completed = 0;
			categoriesCompleted.map((value) => {
				if(value)
					completed++;
			});
			setAmountCompleted(completed);
		}
	}, [categoriesCompleted]);

	return ( 
		<>
			{ activeScene && !upgradePanelActive && cameraPos === 0 && scenes[activeScene]?.categories.map((value, index) => {
				if(showCategories && categoriesCompleted && !categoriesCompleted[index] && showCategories[index]){
					return(
						<InteractivePoint amountCompleted={amountCompleted} key={`interactive-point-${index}`} data={value} category={index}/>
					);
				}
			}) }
		</>
	);
};
 
export default WSI;