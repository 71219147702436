import Typing from 'components/Text/Typing';
import { Header } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useThreeContext } from 'context/three';
import advisors from 'data/advisors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Advisor = (props: {selected: number | undefined}) => {

	const { advisor, activeCategory } = useGlobalContext();
	const {activeScene} = useThreeContext();
	const {t} = useTranslation();

	return ( 
		<Container>
			<Portrait img={advisor !== undefined ? advisors[advisor].portrait : 'null'}/>
			<Text>
				<StyledHeader>{advisor !== undefined ? advisors[advisor].name : 'name'}</StyledHeader>
				<StyledTyping delay={40} animationDuration={100}>
					{props.selected !== undefined ? t(`game.advisors.${advisor}.${activeScene}.category${activeCategory}.upgrade${props.selected}`)
							
						: 'Klik op een van de opties hierboven, en dan geef ik je advies!'}	
				</StyledTyping>
			</Text>
		</Container>
	);
};

// styled components

const Container = styled.div`
	display: flex;
`;

const Portrait = styled.div<{img:string}>`

	flex-shrink: 0;
	@media (max-width: ${p => p.theme.media.lg}){
		height: 50px;
		width: 50px;
		margin-right: ${p => p.theme.whitespace.sm}

    }

    @media (min-width: ${p => p.theme.media.lg}){
		height: 100%;
		aspect-ratio: 1/1;
		margin-right: ${p => p.theme.whitespace.lg}

    }

	/* max-width: 20%; */

	background-color: white;
	border-radius: 100%;

	background-image: url(${p => p.img});
	background-position: center;
	background-size: 100%;
	background-repeat: no-repeat;
`;

const Text = styled.div`
	display: flex; flex-direction: column;
`;

const StyledTyping = styled(Typing)`
	flex-grow: 1;
	overflow-y: auto;



/* color: white; */

@media (max-width: ${p => p.theme.media.lg}){
		font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.3rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.5rem;
    }

`;

const StyledHeader = styled(Header)`
	color: black;

	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 17px;
		/* margin-left: ${p => p.theme.whitespace.sm} */
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.7rem;
		/* margin-left: ${p => p.theme.whitespace.lg} */
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 2rem;
		/* margin-left: ${p => p.theme.whitespace.xl} */
    }
`;
 
export default Advisor;