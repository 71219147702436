
import { circle } from 'assets/icons';
import { Button } from 'components/Buttons';
import { Header, Header2, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import advisors from 'data/advisors';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import Frame from '../components/Frame';
import AdvisorSelection from './AdvisorSelection';
import BuildingSelection, { BuildingOptions } from './BuildingSelection';
 
const Selection = () => {
	const {t} = useTranslation();
	const { setGameState, gameState, setAdvisor, setBuilding } = useGlobalContext();
	const [state, setState] = useState<number>(0); // 0 building select, 1 outro, 2 advisor select, 3 outro and unmount at end.
	const [selected, setSelected] = useState<number>(0);
	const [activeCategory, setActiveCategory] = useState<string>('buildings');
	const [submitActive, setSubmitActive] = useState<boolean>(true);

	const [selectedBuilding, setSelectedBuilding] = useState<number>(0);
	const [selectedAdvisor, setSelectedAdvisor] = useState<number>(0);

	const select = () => {
		setState(e => e + 1);
		setSubmitActive(false);
		setTimeout(() => {
			setState(e => e + 1); 
			setSubmitActive(true);}
		,1000);
	};

	useEffect(() => {
		switch(state){
		case 2: 
			setSelectedBuilding(selected);
			setActiveCategory('advisors');
			break;
		case 3:
			setSelectedAdvisor(selected);
			break;
		case 4: 
			setAdvisor && setAdvisor(selectedAdvisor);
			setBuilding && setBuilding(selectedBuilding);
			setGameState && gameState && setGameState(gameState + 1);
			break;
		}
	}, [state]);

	return (  
		<>
			<Container className={state >= 3 ? 'fade-out' : ''}>
				<Frame style={{justifyContent: 'center', border: 'none', background: 'none', padding: '0'}}>							
					<Header style={{marginLeft: '5%', color: 'white'}}>{t(`selection-${activeCategory}.title`)}</Header>
					<Wrapper>
						{state < 2 && <StyledParagraph style={{marginTop: '0px'}}>{t(`selection-${activeCategory}.description`)}</StyledParagraph>}						
						<Header style={{color: '#0081D6'}}>{activeCategory === 'buildings'? t(`selection-${activeCategory}.title-${selected}`) : 'Schakel hulp in!'}{}</Header>
						<StyledParagraph style={{marginBottom: '0px'}}>{t(`selection-${activeCategory}.description${activeCategory === 'buildings'? `title-${selected}` : ''}`)}</StyledParagraph>
						{/* <StyledParagraph style={{fontWeight:'bold'}}>{t(`selection-${activeCategory}.description`)}</StyledParagraph> */}
						{/* <Spacer/> */}
					</Wrapper>
					<Wrapper flex={true} style={{backgroundColor: '#DFEBF7'}}>				
						<Content className={state === 1 ? 'fade-out' : 'fade-in'}>
							{state >= 2 && <><Header2 style={{color: 'black'}}>{advisors[selected].name}</Header2><StyledParagraph style={{fontWeight: 'bold', marginTop: '0px'}}>{advisors[selected].job}</StyledParagraph></>}
							<StyledParagraph style={{marginTop: '0px', marginBottom: '0px'}}>
								<Trans 
									t={t} 
									i18nKey={`selection-${activeCategory}.description-${selected}`}
									components={{ header: <b />, paragraph: <i />}}
								>
								</Trans>
							</StyledParagraph>
						</Content>
						<StyledButton disabled={!submitActive} onClick={e => {e.currentTarget.blur(); select();}}>Ik kies deze!</StyledButton>
					</Wrapper>
				</Frame>
				<DotRow>
					{[...Array(state < 2 ? BuildingOptions.length : advisors.length)].map((x, index) => 
						<Dot selected={index === selected ? true : false} key={`${index}-dots`}></Dot>
					)}				
				</DotRow>
				{ state < 2 ?
					<BuildingSelection 
						setSelected={(i) => {setSelected(i);}} 
					/>
					: <AdvisorSelection setSelected={(i) => {setSelected(i);}}/>
				}
			</Container>
		</>
	);
};

// keyframes
const onMountAnimation = keyframes`
	0% {
		opacity: 0;
		pointer-events: none;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}
`;

const fadeOut = keyframes`
	0% {
		opacity: 1;
		pointer-events: none;
	}
	100%{
		opacity: 0;
		pointer-events: none;
	}
`;

const fadeIn = keyframes`
	0% {
		opacity: 0;
		pointer-events: none;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}
`;

// styled components

const Container = styled.div`
	position:  relative;
	height: 100%;
	width: 100%;

	animation: ${onMountAnimation} 1s forwards;

	&.fade-out{
		animation: ${fadeOut} 1s forwards; 
	}
`;

const Content = styled.div`
	overflow-y: auto;
	height: 100%;
	max-width:50%;

	&.fade-out{
		animation: ${fadeOut} 1s forwards; 
	}

	&.fade-in{
		animation: ${fadeIn} 1s forwards; 
	}
`;

const DotRow = styled.div`
	position: absolute;
	height:100%;

	transform: rotate(180deg);

	left: 50%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2%;
`;

const Dot = styled.div<{selected: boolean}>`
	background-color: ${p => p.selected ? 'white' : 'none'};

	background-image: url(${circle});
	background-size: cover;

	height: 10px;
	width: 10px;
	border-radius: 100%;
`;

const Wrapper = styled.div<{flex?: boolean}>`
	background-color: white;
	border-radius:  20px;
	width: 90%;
	padding: 5%;
	overflow: auto;
    @media (max-width: ${p => p.theme.media.lg}){
		margin: ${p => p.theme.whitespace.xs} 0px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		margin: ${p => p.theme.whitespace.sm} 0px;
    }

	${p => p.flex && css`
		display: flex;
		flex-direction: row;
		align-items: center;		
	`}
`;

const StyledButton = styled(Button)`

	@media (max-width: ${p => p.theme.media.lg}){
		padding: 5px 10px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		padding: 10px 20px;
    }

	margin: auto;
	margin-right: 0px;
`;

const StyledParagraph = styled(Paragraph)`
	padding-top: 0!important;
    
	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.2rem;
    }

    word-wrap: break-word;
	
`;

export {StyledParagraph};
 
export default Selection;
