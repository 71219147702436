import { arrowdown_hover, arrowdown_normal, arrowup_hover, arrowup_normal } from 'assets/icons';
import { Children, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

type SelectionContainerProps = {
    children: ReactNode;
    setSelected: (i:number) => void;
}

const SelectionContainer = (props: SelectionContainerProps) => {

	const [index, setIndex] = useState<number>(0);
	const childCount = Children.count(props.children) - 1;
	
	useEffect(() => {
		props.setSelected(index);
	}, [index]);

	const up = () => {
		if(props.children){
			if(index + 1 > childCount){
				setIndex(0);
			}
			else{
				setIndex(e => e + 1);
			}
		}
	};

	const down = () => {
		if(props.children){
			if(index - 1 < 0){
				setIndex(childCount);
			}
			else{
				setIndex(e => e - 1);
			}
		}
	};

	return(
		<Container>
			<ArrowRow>
				<ArrowButton direction={'up'} onClick={() => {up();}}/>
			</ArrowRow>
			<Content>
				{ props.children && props.children[index] }
			</Content>
			<ArrowRow>
				<ArrowButton direction={'down'} onClick={() => {down();}}/>
			</ArrowRow>
		</Container>
	);
};

// styled components

const Container = styled.div`
    position: absolute;

    right:0;
    top:0;
    bottom:0;
    left:50%;

    display: flex;
    flex-direction: column;

    @media (max-width: ${p => p.theme.media.lg}){
        margin: ${p => p.theme.whitespace.sm};
    }

    @media (min-width: ${p => p.theme.media.lg}){
        margin: ${p => p.theme.whitespace.lg};
    }

    margin-left:0!important;

`;

const Content = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ArrowRow = styled.div`
    display: flex;
    justify-content: center;
`;

const ArrowButton = styled.button<{direction: string}>`
    @media (max-width: ${p => p.theme.media.lg}){
        height: 35px;
        width: 100px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
            height: 50px;
            width: 200px;
    }

    border: none;
    outline: none;
    border-radius: 50%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0);

    transition: all 0.2s;

    &:hover, :focus{
        transform: translateY(-5px);
    }

    &:active{
        transform: translateY(0);
    }

    ${p => p.direction === 'up' ? css`
        background-image: url(${arrowup_normal});
        &:hover, :focus{
            background-image: url(${arrowup_hover});
        }
    }` : css`
    background-image: url(${arrowdown_normal});
        &:hover, :focus{
            background-image: url(${arrowdown_hover});
        }
    `}
`;

export default SelectionContainer;