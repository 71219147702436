import { logo } from 'assets/images';
import { Button } from 'components/Buttons';
import { Header, Header2, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import { Logo } from '../Introduction';
import Firework from './Fireworks';

const End = () => {

	const { gameState, setGameState, setCategoriesCompleted, setShowCategories } = useGlobalContext();

	const [state, setState] = useState<number>(0); // 0 normal, 1 fade, 2 fade done

	const proceed  = () => {
		setState(1);
		setCategoriesCompleted && setCategoriesCompleted([false,false,false]);
		setShowCategories && setShowCategories([true,false,false]);  
	};

	useEffect(() => {
		if(state === 2){
			setGameState && gameState && setGameState(2);
		}
	}, [state]);

	const {t} = useTranslation();

	return (
		<>
			<Container onAnimationEnd={state === 1 ? () => setState(2) : () => null} state={state}>
				<Wrapper>
					<Content>
						<Header style={{color: '#0081D6'}}>{t('end.title')}</Header>
						<Timed delay={3} style={{textAlign: 'center'}}>
							<Paragraph>{t('end.description1')}</Paragraph>
							<Paragraph >{t('end.description2')}</Paragraph>
							<Paragraph dangerouslySetInnerHTML={{__html: t('end.description3')}}/>
							<Paragraph dangerouslySetInnerHTML={{__html: t('end.description4')}}/>
						</Timed>
						<br/>
						<Timed delay={4}>
							<Logo/>
						</Timed>
						<Timed delay={6}><Button style={{marginTop: '20%'}} disabled={state === 1 ? true : false} onClick={(e) => {e.currentTarget.blur(); proceed();}}> {t('end.playagain_button')} </Button></Timed>

					</Content>					
				</Wrapper>
				<Firework/>
			</Container>
		</>
	);
};

// keyframes

const onMountAnimation = keyframes`
	0%{
		opacity:0;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}
`;

const onUnMountAnimation = keyframes`
	100%{
		opacity:0;
		pointer-events: none;
	}
	0%{
		opacity: 1;
		pointer-events: none;
	}
`;

// styled components

const Container = styled.div<{state:number}>`
	position: relative;

	height: 100%;
	width: 100%;
	opacity: 0;
	overflow: auto;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    overflow: auto;
	animation: ${onMountAnimation} 2s forwards;
	animation-delay: 1s;

	${p => p.state === 1 && css`
		animation: ${onUnMountAnimation} 2s;
	`}
`; 

const Content = styled.div`
	background-color: white;
	text-align: center;
	padding: 15px;
	margin: 20px 0 ;

	border-radius: 25px;
	overflow-y:auto;
	
`;

const Wrapper = styled.div`
	min-height: 100%;
	width: 50%;
	margin: auto auto;
	
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`; 

const Timed = styled.div<{delay:number}>`

	opacity: 0;

	animation: ${onMountAnimation} 2s forwards;
	animation-delay: ${p => p.delay}s;
	pointer-events: none;
`;
export default End;