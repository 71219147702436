import { logo } from 'assets/images';
import { Button } from 'components/Buttons';
import Typing from 'components/Text/Typing';
import { Header, Header2, Paragraph } from 'components/Typography';
import { useGlobalContext } from 'context/global';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
 
const Introduction = () => {

	const { gameState, setGameState } = useGlobalContext();

	const [state, setState] = useState<number>(0); // 0 intro, 1 normal, 2 outro, 3 ready for unmount

	const proceed  = () => {
		setState(2);
	};

	useEffect(() => {
		if(state === 3){
			setGameState && gameState && setGameState(gameState + 1);
		}
	}, [state]);

	const {t} = useTranslation();

	return (<>
		<Container onAnimationEnd={state === 2 ? () => setState(3) : () => null} state={state}>
			<Wrapper>
				<Header style={{color: '#0081D6'}}>{t('introduction.title')}</Header>
				<Timed delay={3} style={{textAlign: 'center'}}>
					<Paragraph>{t('introduction.description')}</Paragraph>
				</Timed>
				<br/>
				<Timed delay={4}>
					<Logo/>
				</Timed>
			</Wrapper>
			<Timed delay={6}><Button style={{marginTop: '20%', pointerEvents: 'all'}} disabled={state === 2 ? true : false} onClick={(e) => {e.currentTarget.blur(); proceed();}}> {t('introduction.start_button')} </Button></Timed>
		</Container>
	</>
	);
};

// keyframes

const onMountAnimation = keyframes`
	0%{
		opacity:0;
	}
	100%{
		opacity: 1;
		pointer-events: all;
	}
`;

const onUnMountAnimation = keyframes`
	100%{
		opacity:0;
		pointer-events: none;
	}
	0%{
		opacity: 1;
		pointer-events: none;
	}
`;

// styled components

const Container = styled.div<{state:number}>`
	position: relative;
	overflow: auto;

	height: 100%;
	width: 50%;
	opacity: 0;
	margin:auto;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    overflow: auto;


	animation: ${onMountAnimation} 2s forwards;
	animation-delay: 1s;

	${p => p.state === 2 && css`
		animation: ${onUnMountAnimation} 2s;
	`}
`; 

const Wrapper = styled.div`
	background-color: white;
	text-align: center;
	padding: 5%;
	border-radius: 25px;
	height: max-content;

`; 

export const Logo = styled.div`

	width: 100%;

	background-image: url(${logo});
	background-size: contain;
	background-position: right bottom;
	background-repeat: no-repeat;


	@media (max-width: ${p => p.theme.media.lg}){
		height: 30px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
		height: 60px;
    }
`; 

const Timed = styled.div<{delay:number}>`

	opacity: 0;

	animation: ${onMountAnimation} 2s forwards;
	animation-delay: ${p => p.delay}s;
	pointer-events: none;
`;

export default Introduction;