import { Vector3 } from '@react-three/fiber';
import * as THREE from 'three';
import { useGlobalContext } from 'context/global';
import cameraPositions from 'data/cameraPositions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CameraControls } from './camera-controls';
import { removeAllListeners } from 'process';

const Controls = (props: {enableZoom?:boolean}) => {

	const cameraControls = useRef<CameraControls | null>(null);
	const [initialized, setInitialized] = useState<boolean>(false);

	const {cameraPos, setCameraPosReached, upgradePanelActive} = useGlobalContext();
	const [targetPos, setTargetPos] = useState<Vector3 | undefined>();
	const [movedSinceRotate, setMovedSinceRotate] = useState<boolean>(true);

	useEffect(() => {

		if(!movedSinceRotate && cameraPos !== 0){
			setCameraPosReached && setCameraPosReached();
			return;
		}

		if(cameraPos){
			setTargetPos(cameraPositions[cameraPos - 1]);
		}
		else{
			if(targetPos){
				setTargetPos(undefined);
				if(cameraControls.current){
					cameraControls.current.enabled = true;
				}
			}
		}
	},[cameraPos]);

	// const [trucked, setTrucked] = useState<boolean>(false);

	// useEffect(() => {
	// 	if(upgradePanelActive && !trucked){
	// 		cameraControls.current && cameraControls.current.truck(-5, 0, true);
	// 		cameraControls.current && cameraControls.current.setOrbitPoint(-5,0,0);
	// 		setTrucked(true);
	// 	}
	// 	else if(!upgradePanelActive && trucked){
	// 		cameraControls.current && cameraControls.current.truck(5, 0, true);
	// 		cameraControls.current && cameraControls.current.setOrbitPoint(5,0,0);

	// 		setTrucked(false);
	// 	}
	// }, [upgradePanelActive]);

	useEffect(() => {
		if(targetPos && cameraControls.current){
			// @ts-ignore
			cameraControls.current.addEventListener('rest', (a) => {onRest(a);});
			cameraControls.current.enabled = false;
			// const normalizedAzimuthAngle = THREE.MathUtils.euclideanModulo( cameraControls.current.azimuthAngle, 360 * THREE.MathUtils.DEG2RAD );
			cameraControls.current.normalizeRotations();
			cameraControls.current.rotateTo(targetPos[0], targetPos[1], true);
		}
	}, [targetPos]);

	const onRest = useCallback((a) => {
		if(targetPos){
			setMovedSinceRotate(false);
			setCameraPosReached && setCameraPosReached();
			cameraControls.current?.removeAllEventListeners();
			cameraControls.current?.addEventListener('control',() => { setMovedSinceRotate(true); cameraControls.current?.removeAllEventListeners(); } );
		}
	},[targetPos, setTargetPos]);

	useEffect(() => {
		if(cameraControls.current && !initialized){
			// cameraControls.current.minAzimuthAngle = Math.PI / -4;
			// cameraControls.current.maxAzimuthAngle = Math.PI / 4;
			cameraControls.current.minPolarAngle = (Math.PI / 2)-1.2;
			cameraControls.current.maxPolarAngle = (Math.PI / 2)-0.2;
			cameraControls.current.minDistance = 3; cameraControls.current.maxDistance = 5;
			cameraControls.current.distance = 5;
			cameraControls.current.polarAngle = 1;
			cameraControls.current.azimuthAngle = 2;
			cameraControls.current.enabled = props.enableZoom ? props.enableZoom : true;
			cameraControls.current.truckSpeed = 0;
			setInitialized(true);
		}
	},[cameraControls, setInitialized]);

	return (
		<CameraControls ref={cameraControls}
		/>
	);
};


export default Controls;