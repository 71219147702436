import { useThreeContext } from 'context/three';
import { useTranslation } from 'react-i18next';
import { ConsoleLabel, ConsoleRow } from './components';

const ConsoleUpgradeSelect = () => {

	const { t } = useTranslation();
	const {activeUpgrade, setActiveUpgrade} = useThreeContext();

	const newArray = (index) => {   
		const newarray = activeUpgrade && [ ...activeUpgrade];
		if(newarray){
			newarray[index] = !newarray[index];		
			setActiveUpgrade && setActiveUpgrade([ ...newarray]);
		}
	};   


	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.6')}</ConsoleLabel>
			<button onClick={() => newArray(0)}>upgrade 1</button>
			<button onClick={() => newArray(1)}>upgrade 2</button>
			<button onClick={() => newArray(2)}>upgrade 3</button>
			<button onClick={() => newArray(3)}>upgrade 4</button>
			<button onClick={() => newArray(4)}>upgrade 5</button>
			<button onClick={() => newArray(5)}>upgrade 6</button>
			<button onClick={() => newArray(6)}>upgrade 7</button>
			<button onClick={() => newArray(7)}>upgrade 8</button>
			<button onClick={() => newArray(8)}>roof</button>

		</ConsoleRow>
	);
};

export default ConsoleUpgradeSelect;