/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { zwembadcover_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
  nodes: {
    pool_coverr: THREE.Mesh
  }
  materials: {
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Zwembadcover_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(zwembadcover_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);
  
	return (
		// position={[13.07,1,-12.5]} als offset
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}  position={[13.07,1,-12.5]}>
			<mesh
				name="pool_coverr"
				castShadow
				receiveShadow
				geometry={nodes.pool_coverr.geometry}
				material={materials.kcs_atlas}
				morphTargetDictionary={nodes.pool_coverr.morphTargetDictionary}
				morphTargetInfluences={nodes.pool_coverr.morphTargetInfluences}
			/>
		</group>
	);
}

useGLTF.preload(zwembadcover_upgrade_m);
