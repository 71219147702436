/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { battery_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
	nodes: {
		battery: THREE.Mesh
		battery2: THREE.Mesh
		battery1: THREE.Mesh
	}
	materials: {
		darkline: THREE.MeshStandardMaterial
		['Metal.002']: THREE.MeshStandardMaterial
		orange: THREE.MeshStandardMaterial
	}
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Battery_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(battery_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if (actions) {
			for (const action in actions) {
				actions[action].setLoop(THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;
			}
		}
	}, [actions]);

	useEffect(() => {
		if (props.upgraded) {
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	}, [props]);

	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.battery.geometry}
				material={materials.darkline}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.battery2.geometry}
				material={materials['Metal.002']}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.battery1.geometry}
				material={materials.orange}
			/>
		</group>
	);
}

useGLTF.preload(battery_upgrade_m);
