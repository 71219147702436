/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { windturbine_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
  nodes: {
    ['windturbine-Metal_04']: THREE.Mesh
    windturbine1: THREE.Mesh
  }
  materials: {
    ['Metal_04.002']: THREE.MeshStandardMaterial
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Windturbine_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(windturbine_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);

	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<group>
				<mesh
					geometry={nodes['windturbine-Metal_04'].geometry}
					material={materials['Metal_04.002']}
				/>
			</group>
			<mesh
				name="windturbine1"
				geometry={nodes.windturbine1.geometry}
				material={materials.kcs_atlas}
			/>
		</group>
	);
}

useGLTF.preload(windturbine_upgrade_m);
