
import { useThreeContext } from 'context/three';
import { useEffect, useState } from 'react';
import Tennisbaan from '../objects/Tennisbaan/Tennisbaan';
import Tennisbaan_roof from '../objects/Tennisbaan/Tenniscourt_roof';
import Battery_upgrade from '../objects/Upgrades/Battery_01';
import Warmte_vries_cointainer_upgrade from '../objects/Upgrades/Container_warmte-koud-opslag_upgrade';
import Ecobarrel_upgrade from '../objects/Upgrades/Ecovat_upgrade';
import Flora_01 from '../objects/Upgrades/Flora_01';
import Flora_02 from '../objects/Upgrades/Flora_02';
import Flora_03 from '../objects/Upgrades/Flora_03';
import Warmte_vries_upgrade from '../objects/Upgrades/Icecube__warmte-koud-opslag_upgrade';
import Kunstgras_upgrade from '../objects/Upgrades/Kunstgras_upgrade';
import Led_upgrade from '../objects/Upgrades/Led_upgrade';
import Solarpanels_upgrade from '../objects/Upgrades/Solarpanels';
import Warmtepomp_upgrade from '../objects/Upgrades/Warmtepomp_upgrade';
import Windturbine_upgrade from '../objects/Upgrades/Windturbine_upgrade';


const Scene = () => {
	const {activeUpgrade} = useThreeContext();
	const [greenUpgrades, setGreenUpgrades] = useState<boolean[]>([false, false, false]);

	useEffect(() => {
		if(activeUpgrade){
			if(activeUpgrade[0] || activeUpgrade[1] || activeUpgrade[2]){
				setGreenUpgrades([true, greenUpgrades[1], greenUpgrades[2]]);
			}
			if(activeUpgrade[3] || activeUpgrade[4]){
				setGreenUpgrades([greenUpgrades[0], true, greenUpgrades[2]]);
			}
			if(activeUpgrade[5] || activeUpgrade[6] || activeUpgrade[7]){
				setGreenUpgrades([greenUpgrades[0], greenUpgrades[1], true]);
			}
		}
	},[activeUpgrade]);

	return(
		<group rotation={[0, 2, 0]}>	
			<Tennisbaan/>
			<group position={[-2, 0.044, 1]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[-2.1, 0.044, 1.8]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[1.4, 0.044, -2.5]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[-1.4, 0.044, -2.5]}>
				<Flora_02 upgraded={greenUpgrades[1]}/>
			</group>
			<group rotation={[0, 0.5, 0]} position={[2.9, 0.044, -0.1]}>
				<Flora_03 upgraded={greenUpgrades[2]}/>  
			</group> 	
			<Tennisbaan_roof upgraded={activeUpgrade && activeUpgrade[0]}/>		
			<group scale={0.3} position={[0.5,2,-1.2]}>
				<Led_upgrade upgraded={activeUpgrade && activeUpgrade[0]}/>
			</group>
			<group scale={1.5} position={[-0.519, 0.04, -0.15]}>
				<Warmtepomp_upgrade upgraded={activeUpgrade && activeUpgrade[1]}/>	
			</group>
			<group position={[3, 0.08, -1.302]} scale={0.8}>
				<Warmte_vries_cointainer_upgrade upgraded={activeUpgrade && activeUpgrade[2]}/>
			</group>
			<group position={[-0.65, 0.05, 1.48]}>
				<Kunstgras_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
			</group>
			<group position={[-1.85,-0.1,1]}>
				<Windturbine_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
			</group>
			<group position={[-0.6,0,0]}>
				<group position={[0,1.47,-1.8]}>
					<group position={[0,0,0.5]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
					<group position={[0,0,1]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
				</group>
				<group position={[0.6,1.47,-1.8]}>
					<group position={[0,0,0.5]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
					<group position={[0,0,1]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
				</group>
				<group position={[1.2,1.47,-1.8]}>
					<group position={[0,0,0.5]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
					<group position={[0,0,1]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
				</group>
				<group position={[1.8,1.47,-1.8]}>
					<group position={[0,0,0.5]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
					<group position={[0,0,1]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
				</group>
				<group position={[2.4,1.47,-1.8]}>
					<group position={[0,0,0.5]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
					<group position={[0,0,1]}><Solarpanels_upgrade  upgraded={activeUpgrade && activeUpgrade[5]}/></group>
				</group>
			</group>
			<group position={[-1.133,0,0.35]} scale={0.6}><Ecobarrel_upgrade upgraded={activeUpgrade && activeUpgrade[6]}/></group>
			<group position={[0.8, 0.05, 0]} scale={0.3}>
				<Battery_upgrade upgraded={activeUpgrade && activeUpgrade[7]}/>
			</group>
		</group>  
	);
};

export default Scene;