import Console from 'components/Console';
import Input from 'components/Input';
import { Controls, Lighting, ThreeCanvas } from 'components/Three';
import { GlobalContextProvider } from 'context/global';
import React, { Suspense }  from 'react';
import Layout from './components/Layout';
import { I18nextProvider } from 'react-i18next';
import i18next from './components/Localization/index';
import ForceLandscape from 'components/ForceLandscape';
import { AudioContextProvider } from 'context/audio';
import { ThreeContextProvider } from 'context/three';
import Scene from 'components/Three/scenes';
import Screens from 'components/Screens';
import Manager from 'components/Three/scenes/UpgradeManager';
import Backdrop from 'components/Background';

const App = () => { 
	
	return (
		<Suspense fallback={ (<div>Loading...</div>)} >
			<Layout>
				<I18nextProvider i18n={i18next}>
					<GlobalContextProvider>
						<ThreeContextProvider>
							<AudioContextProvider>
								{/* end providers */}
								<>
									<Backdrop/>
									<ForceLandscape/>				
									<Input />
									<Console />
									<Screens/> {/* Different screens */}
									<Suspense fallback={<span>loading...</span>}>
										<ThreeCanvas >
											<Controls/>
											<Lighting/>
											<Scene/>
										</ThreeCanvas>
									</Suspense>
								</>
							</AudioContextProvider>
						</ThreeContextProvider>
					</GlobalContextProvider>
				</I18nextProvider>
			</Layout>
		</Suspense>
	);
};

export default App;
