import styled from 'styled-components';

const Header = styled.h1`
    font-weight: bold;
    margin-block-start: 0!important;
    color: ${p => p.theme.colors.blue};
    @media (max-width: ${p => p.theme.media.lg}){
        font-size: 17px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.8rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 2rem;
    }

`;

const Header2 = styled.h2`
    font-weight: bold;
    margin-block-start: 0!important;
    margin-block-end: 0.2rem;

    @media (max-width: ${p => p.theme.media.lg}){
        font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.5rem;
    }

`;

const Paragraph = styled.p`
    padding-top: 0!important;
    
	@media (max-width: ${p => p.theme.media.lg}){
        font-size: 14px;
    }

    @media (min-width: ${p => p.theme.media.lg}){
        font-size: 1.3rem;
    }

    @media (min-width: ${p => p.theme.media.xl}){
        font-size: 1.5rem;
    }

    word-wrap: break-word;
`;

export {Header, Header2, Paragraph};