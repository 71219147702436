
import { useThreeContext } from 'context/three';
import Led_upgrade from '../objects/Upgrades/Led_upgrade';
import Warmtepomp_upgrade from '../objects/Upgrades/Warmtepomp_upgrade';
import Solarparking_upgrade from '../objects/Upgrades/Solarparking';
import Warmtepanelen_upgrade from '../objects/Upgrades/Warmtepaneel_upgrade';
import Ecobarrel_upgrade from '../objects/Upgrades/Ecovat_upgrade';
import Battery_upgrade from '../objects/Upgrades/Battery_01';
import Sporthal from '../objects/Sporthal/Sporthal';
import Sporthal_roof from '../objects/Sporthal/Sportshall_roof';
import Solarpanels_upgrade from '../objects/Upgrades/Solarpanels';
import Gevelisolatie_upgrade from '../objects/Upgrades/Wall_isolation_upgrade';
import Flora_01 from '../objects/Upgrades/Flora_01';
import Flora_02 from '../objects/Upgrades/Flora_02';
import Flora_03 from '../objects/Upgrades/Flora_03';
import { useEffect, useState } from 'react';


const Scene = () => {

	const {activeUpgrade} = useThreeContext();
	const [greenUpgrades, setGreenUpgrades] = useState<boolean[]>([false, false, false]);

	useEffect(() => {
		if(activeUpgrade){
			if(activeUpgrade[0] || activeUpgrade[1] || activeUpgrade[2]){
				setGreenUpgrades([true, greenUpgrades[1], greenUpgrades[2]]);
			}
			if(activeUpgrade[3] || activeUpgrade[4]){
				setGreenUpgrades([greenUpgrades[0], true, greenUpgrades[2]]);
			}
			if(activeUpgrade[5] || activeUpgrade[6] || activeUpgrade[7]){
				setGreenUpgrades([greenUpgrades[0], greenUpgrades[1], true]);
			}
		}
	},[activeUpgrade]);

	return(
		<group rotation={[0, 2, 0]}>	
			<Sporthal/>
			<group position={[-2.3, 0.084, 1]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[1.6, 0.084, 1.8]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[1.4, 0.084, 2]}><Flora_01 upgraded={greenUpgrades[0]}/></group>
			<group position={[0.8, 0.084, 0.2]}>
				<Flora_02 upgraded={greenUpgrades[1]}/>
			</group>
			<group position={[-0.36, 0.084, 1.8]}>
				<Flora_03 upgraded={greenUpgrades[2]}/>  
			</group> 
			<Sporthal_roof upgraded={activeUpgrade && activeUpgrade[0]}/>
			<group scale={0.35} position={[0, 2.156, -0.5]}>			
				<Led_upgrade upgraded={activeUpgrade && activeUpgrade[0]}/>
			</group>
			<group position={[2.3, 0.656, -0.5]}>
				<Gevelisolatie_upgrade upgraded={activeUpgrade && activeUpgrade[1]}/>
			</group>
			<group rotation={[-0, 0, 3.1]} position={[-2.2, 0.656, -0.5]}>
				<Gevelisolatie_upgrade upgraded={activeUpgrade && activeUpgrade[1]}/>
			</group>
			<group rotation={[-0, 1.6, 0]} position={[0, 0.656, -1.3]}>
				<Gevelisolatie_upgrade upgraded={activeUpgrade && activeUpgrade[1]}/>
			</group>
			<group scale={1.5} position={[-1.6, 0.056, -0.046]}>
				<Warmtepomp_upgrade upgraded={activeUpgrade && activeUpgrade[2]}/>
			</group>
			<group position={[-0.6, 1.4, -0.4]}>
				<group position={[0, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[0.6, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[1.2, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
			</group>
			<group position={[-0.6, 1.4, -0.8]}>
				<group position={[0, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[0.6, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
				<group position={[1.2, 0, 0]}>
					<Solarpanels_upgrade upgraded={activeUpgrade && activeUpgrade[3]}/>
				</group>
			</group>
			<group position={[0, 1.15, -0.5]}>
				<group position={[1.2, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group position={[1.6, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group position={[2, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group position={[-1.2, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group position={[-1.6, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
				<group position={[-2, 0, 0]}>
					<Warmtepanelen_upgrade upgraded={activeUpgrade && activeUpgrade[4]}/>
				</group>
			</group>
			<group position={[0, 0, 3]}>
				<group rotation={[0, 1.55, 0]} position={[-0.914, 0.0225, -1.7853]}>
					<Solarparking_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
				</group>
				<group rotation={[0, 1.55, 0]} position={[-0.914, 0.0225, -1.2]}>
					<Solarparking_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
				</group>
				<group rotation={[0, 1.55, 0]} position={[-1.97, 0.022, -1.1]}>
					<Solarparking_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
				</group>
				<group rotation={[0, 1.55, 0]} position={[-1.97, 0.022, -2.3]}>
					<Solarparking_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
				</group>
				<group rotation={[0, 1.55, 0]} position={[-1.97, 0.022, -1.7]}>
					<Solarparking_upgrade upgraded={activeUpgrade && activeUpgrade[5]}/>
				</group>
			</group>
			<group scale={0.7} position={[1.34, 0, 1.555]}>
				<Ecobarrel_upgrade upgraded={activeUpgrade && activeUpgrade[6]}/>
			</group>
			<group scale={0.35} position={[-1.276, 0,0.221]}>
				<Battery_upgrade upgraded={activeUpgrade && activeUpgrade[7]}/>
			</group>
		</group>  
	);
};

export default Scene;