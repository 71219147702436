/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { solarparking_upgrade_m } from 'assets/models';
import { UpgradeProps } from '../types';

type GLTFResult = GLTF & {
  nodes: {
    ['solarparking-White']: THREE.Mesh
    ['solarparking-glass_02']: THREE.Mesh
    ['solarparking-Metal']: THREE.Mesh
    ['solarparking2-Metal_04']: THREE.Mesh
    ['solarparking1-concrete']: THREE.Mesh
    ['solarparking1-Metal']: THREE.Mesh
  }
  materials: {
    ['White.003']: THREE.MeshStandardMaterial
    ['glass_02.001']: THREE.MeshStandardMaterial
    ['Metal.003']: THREE.MeshStandardMaterial
    kcs_atlas: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Anim_0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Solarparking_upgrade({ ...props }: JSX.IntrinsicElements['group'] | UpgradeProps) {
	const group = useRef<THREE.Group>();
	const { nodes, materials, animations } = useGLTF(solarparking_upgrade_m) as GLTFResult;
	const { actions } = useAnimations<GLTFActions>(animations, group);
	const [ upgradeAnimationPlayed, setUpgradeAnimationPlayed] = useState<boolean>(false);

	useEffect(() => {
		if(actions){		
			for (const action in actions) {
				actions[action].setLoop( THREE.LoopOnce, 1);
				actions[action].timeScale = 0.3;
				actions[action].clampWhenFinished = true;	
			}
		}
	},[actions]);

	useEffect(() => {	
		if(props.upgraded){
			actions['Anim_0'].play();
			setUpgradeAnimationPlayed(true);
		} else {
			actions['Anim_0'].reset();
		}
	},[props]);

	return (
		<group visible={props.upgraded? true : false} ref={group} {...props} dispose={null}>
			<group>
				<mesh
					geometry={nodes['solarparking-White'].geometry}
					material={materials['White.003']}
				/>
				<mesh geometry={nodes['solarparking-glass_02'].geometry} material={materials['glass_02.001']} />
				<mesh geometry={nodes['solarparking-Metal'].geometry} material={materials['Metal.003']} />
			</group>
			<group>
				<mesh
					geometry={nodes['solarparking2-Metal_04'].geometry}
					material={nodes['solarparking2-Metal_04'].material}
				/>
			</group>
			<group>
				<mesh
					name="solarparking1-concrete"
					geometry={nodes['solarparking1-concrete'].geometry}
					material={nodes['solarparking1-concrete'].material}
				/>
				<mesh geometry={nodes['solarparking1-Metal'].geometry} material={nodes['solarparking1-Metal'].material} />
			</group>
		</group>
	);
}

useGLTF.preload(solarparking_upgrade_m);
